// Author: Ryan Pinkney
// Handles the logging in of the account

import React, { useState, useContext } from "react";
import "./Login.css";
import { supabase } from "../../supabase/supabase";
import lanesLogo from "../../images/Logo_Color.svg";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/userContext";
import { stripe } from "../../stripe/stripe";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false); // New state to track loading
  const navigate = useNavigate();
  const { setUserId } = useContext(UserContext);

  const signInWithEmail = async () => {
    setLoading(true);
    const { data, error } = await supabase.auth.signInWithPassword({
      email: email,
      password: password,
    });
    // console.log(data);
    // console.log(supabase.auth);

    if (error) {
      setLoading(false);
      alert("Password or username was incorrect. Try again.");
    } else {
      const user_id = data.session.user.id;
      await setUserId(user_id);

      // ************** Stripe Logic **********************************
      const customers = await stripe.customers.list({
        email: email,
      });

      // Potentially use this code when we set up a customer subscription
      // const subscriptions = customerData.subscriptions.data;

      // // Check if the user has an active subscription
      // const hasActiveSubscription = subscriptions.some((subscription) => subscription.status === 'active');
      // console.log(hasActiveSubscription);

      if (customers.data.length > 0 && customers.data[0].balance === 0) {
        // User has an active subscription, proceed with login
        await updatePaymentStatus(user_id, customers);
      }
      // **************************************************************

      navigate("/dashboard");
    }
  };

  const updatePaymentStatus = async (user_id, customers) => {
    try {
      const { error } = await supabase
        .from("profiles")
        .update({
          payment_active: true,
          stripe_customer_id: customers.data[0].id,
        })
        .eq("id", user_id);

      if (error) {
        throw new Error(`Error updating load: ${error.message}`);
      }

      return Promise.resolve();
    } catch (error) {
      throw new Error(`Error updating load: ${error.message}`);
    }
  };

  // Function to handle sign-up link click
  const handleSignUpClick = () => {
    // Your logic for redirecting to sign-up page or showing sign-up modal
    navigate("/signup");
  };

  return (
    <div className="container_login">
      <div className="photo_center_login">
        <img src={lanesLogo} alt="Lanes Logo" />
      </div>
      <div className="login-form">
        <input
          type="email"
          placeholder="Email"
          className="form-input"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          className="form-input"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button className="btn btn-login " onClick={signInWithEmail}>
          {loading ? "Loading..." : "Log in"}
        </button>
        {/* Hyperlink styled as text */}
        <p style={{ userSelect: "none" }}>
          Don't have an account?{" "}
          <span className="sign-up-link" onClick={handleSignUpClick}>
            Sign Up
          </span>
        </p>
      </div>
    </div>
  );
}

export default Login;
