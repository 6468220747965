// Author Stetson Kent
// Upload the proof of delivery to supabase
import { supabase } from './supabase';

// Function to upload the proof of delivery to Supabase
const uploadDeliveryConfirmation = async (file, load_id, type) => {
  try {
    if (!file) {
      console.log('Error: Please provide a file before updating.');
      return null;
    }

    // console.log('Uploaded file:', file.name, 'Load ID:', load_id);
    // console.log('File object:', file);
    // console.log('File name:', file.name);

    const fileName = file.name;

    let contentType = 'application/pdf';
    if (fileName.endsWith('.jpg') || fileName.endsWith('.jpeg')) {
      contentType = 'image/jpeg';
    } else if (fileName.endsWith('.png')) {
      contentType = 'image/png';
    }

    // Use FormData to prepare the file for upload
    const formData = new FormData();
    formData.append('file', file);

    // Pass to Supabase
    const { data, error } = await supabase.storage
      .from('images') // Replace with your storage bucket name
        .upload(`${load_id}/${type}/${fileName}`, file, { 
        contentType: contentType,
        upsert: true, 
        cacheControl: "3600",
      });

    if (error) {
      throw error;
    }

    // Successfully uploaded
    // console.log('Success: PDF updated successfully!');

    const publicUrl = await getDeliveryConfirmationPublicUrl(load_id, `${type}/${fileName}`);

    if (publicUrl) {
      // console.log('Public URL:', publicUrl);
      return publicUrl;
    } else {
      console.log('Error: Failed to generate public URL');
      return null;
    }
  } catch (error) {
    console.error('Error uploading proof of delivery: ', error.message);
    console.log('Error: Failed to upload delivery confirmation. Please try again.');
  }
};

// Function to get the public URL of the uploaded file
const getDeliveryConfirmationPublicUrl = async (load_id, fileName) => {
  try {
    // Generate a signed URL for the file
    const { data, error } = await supabase.storage
      .from('images')
      .getPublicUrl(`${load_id}/${fileName}`);

    if (error) {
      console.error('Error generating signed URL:', error.message);
      return null;
    }

    // Return the public URL
    return data.publicUrl;
  } catch (error) {
    console.error('Error:', error.message);
    return null;
  }
};

// Function to delete the rate confirmation
const deleteDeliveryConfirmation = async (load_id) => {
  try {
    // Delete the delivery confirmation folder after uploading
    await supabase.storage
      .from('images')
      .remove([load_id]);

    console.log('Delivery confirmation folder deleted:', load_id);
  } catch (error) {
    console.error('Error deleting delivery confirmation:', error.message);
  }
};

export { uploadDeliveryConfirmation, getDeliveryConfirmationPublicUrl, deleteDeliveryConfirmation };
