// David Schraedel
// displays loads returned when a driver searches the database

import "./SearchResults.css";
import { IoCaretBack, IoCaretForward } from "react-icons/io5";
import { getAvailableLoadCount } from "../../supabase/searchLoads";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function SearchResults({ searchResults, changePage, page }) {
  const navigate = useNavigate();

  const handleRowClick = (loadId) => {
    navigate(`/load-details/${loadId}`);
  };

  return (
    <>
      {searchResults && (
        <div className="search-results-container">
          <h2 className="search-results-header">Search Results</h2>
          <table className="table-dashboard">
            <thead>
              <tr>
                <th>Origin</th>
                <th>Rate</th>
                <th>Destination</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {searchResults.map((load, index) => (
                <tr
                  key={index}
                  className={`pill-row ${load.load_status === "pending" ? "pending" : ""}`}
                  onClick={() => handleRowClick(load.load_id)}>
                  <td>
                    {load.origin_address_city}, {load.origin_address_state}
                  </td>
                  <td>${load.rate_per_mile}</td>
                  <td>
                    {load.destination_address_city}, {load.destination_address_state}
                  </td>
                  <td>
                    {load.load_status.charAt(0).toUpperCase() + load.load_status.slice(1)}
                    {load.load_status === "pending" && (
                      <span className="notification">&#9679;</span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="pagination-container">
            <button
              className="pagination-button"
              onClick={() => changePage(-1)}
              disabled={page === 1}>
              <IoCaretBack size={20} className="pagination-icon" />
            </button>
            <button
              className="pagination-button"
              onClick={() => changePage(+1)}
              disabled={(page + 1) * 10 >= searchResults.length + 10}>
              <IoCaretForward size={20} className="pagination-icon" />
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default SearchResults;
