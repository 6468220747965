// Author: Ryan Pinkney
// Define all the functions that interact with all our data tables
import { supabase } from './supabase';


// FETCH ALL LOADS FROM THE LOADS TABLE
const getLoads = async () => {
  const { data: loaddata, error: loaderror } = await supabase.from('loads').select('*').or('load_status.eq.new');

  if (loaderror) {
    console.error('Error fetching data:', loaderror);
    return;
  } else {

    return loaddata;
  }

};

// RETURN ALL FIELDS FOR THE LOAD WHERE load_id = load_id
const getSingleLoad = async (load_id) => {
  // If load_id is provided, fetch only the specific load
  if (load_id) {
    
    const { data: loadDetails, error: loadError } = await supabase
      .from('loads')
      .select('*')
      .eq('load_id', load_id);

    if (loadError) {
      console.error('Error fetching load details:', loadError);
      return;
    } else {
      return loadDetails;
    }
  }
};

// RETURN ALL LOADS WHERE driver_id EQUALS THE CURRENT USERS ID
const getCurrentDriverLoads = async (driver_id) => {
  // If shipper_id is provided, fetch only the specific load_id
  if (driver_id) {
    
    const { data: loadDetails, error: loadError } = await supabase
      .from('userloads')
      .select('load_id')
      .eq('user_id', driver_id)
      .eq('relationship_type', 'driver');

    if (loadError) {
      console.error('Error fetching load details:', loadError);
      return;
    } else {
      // Extract load_ids from loadDetails
      const loadIds = loadDetails.map(detail => detail.load_id);

      // Fetch loads from the loads table based on load_ids
      const { data: loads, error: loadsError } = await supabase
        .from('loads')
        .select('*')
        .in('load_id', loadIds);

      if (loadsError) {
        console.error('Error fetching loads:', loadsError);
        return;
      } else {
        return loads;
      }
    }
  }
};

// RETURN ALL LOADS WHERE shipper_id EQUALS THE CURRENT USERS ID
const getCurrentShipperLoads = async (shipper_id) => {
  // If shipper_id is provided, fetch only the specific load_id
  if (shipper_id) {
    
    const { data: loadDetails, error: loadError } = await supabase
      .from('userloads')
      .select('load_id')
      .eq('user_id', shipper_id)
      .eq('relationship_type', 'shipper');

    if (loadError) {
      console.error('Error fetching load details:', loadError);
      return;
    } else {
      // Extract load_ids from loadDetails
      const loadIds = loadDetails.map(detail => detail.load_id);

      // Fetch loads from the loads table based on load_ids
      const { data: loads, error: loadsError } = await supabase
        .from('loads')
        .select('*')
        .in('load_id', loadIds);

      if (loadsError) {
        console.error('Error fetching loads:', loadsError);
        return;
      } else {
        return loads;
      }
    }
  }
};


// Function used to upade the status of the load. Used in various locations within the application
const updateLoadStatus = async (load_id, load_status) => {
  try {
      // Update the status of the corresponding record to 'Pending'
      const { data, error } = await supabase
          .from('loads')
          .update({ load_status: load_status})
          .eq('load_id', load_id);

      if (error) {
          console.error('Error updating load status:', error);
          return { success: false, error: error.message };
      }

      // Check if any records were updated
      if (data && data.length > 0) {
          return { success: true, message: 'Load status updated to successfully' };
      } else {
          return { success: false, message: 'No records found with the provided load_id' };
      }
  } catch (error) {
      console.error('Error updating load status:', error);
      return { success: false, error: error.message };
  }
};

// Function used to upade the status of the load. Used in various locations within the application
const updateLoadImageUrl = async (load_id, proofOfDeliveryUrl, additionalFilesUrls) => {
  try {
      // Update the status of the corresponding record to 'Pending'
      const { data, error } = await supabase
        .from('loads')
        .update({
          dropoff_image_url: proofOfDeliveryUrl,
          additional_files: additionalFilesUrls,
        })
        .match({ load_id: load_id });

      if (error) {
          console.error('Error updating url:', error);
          return { success: false, error: error.message };
      }

      // Check if any records were updated
      if (data && data.length > 0) {
          return { success: true, message: 'Load url updated to successfully' };
      } else {
          return { success: false, message: 'No records found with the provided load_id' };
      }
  } catch (error) {
      console.error('Error updating load url:', error);
      return { success: false, error: error.message };
  }
};

// Function used to update the rate confirmation url in the loads table
const updateRateConfirmationUrl = async (load_id, url, isSigned) => {
  try {
    if (!url) {
      console.log('Error updating rate confirmation url: URL is undefined');
      return { success: false, error: 'URL is undefined' };
    }

    // Append "_signed" to the file name if it's a signed rate confirmation
    const signedUrl = isSigned ? url.replace('.pdf', '_signed.pdf') : url;

    // Update the rate confirmation url of the corresponding record
    const { data, error } = await supabase
      .from('loads')
      .update({ rate_confirmation_url: signedUrl })
      .eq('load_id', load_id);

    if (error) {
      console.error('Error updating rate confirmation url:', error.message);
      return { success: false, error: error.message };
    }

    // Check if any records were updated
    if (data && data.length > 0) {
      return { success: true, message: 'Rate confirmation URL updated successfully' };
    } else {
      return { success: false, message: 'No records found with the provided load_id' };
    }
  } catch (error) {
    console.error('Error updating rate confirmation url:', error.message);
    return { success: false, error: error.message };
  }
};

// Function used to update the bill of lading url in the loads table
const updateBillOfLadingUrl = async (load_id, url) => {
  try {
    if (!url) {
      console.log('Error updating bill of lading url: URL is undefined');
      return { success: false, error: 'URL is undefined' };
    }

    // Update the bill of lading url of the corresponding record
    const { data, error } = await supabase
      .from('loads')
      .update({ bill_of_lading_url: url })
      .eq('load_id', load_id);

    if (error) {
      console.error('Error updating bill of lading url:', error.message);
      return { success: false, error: error.message };
    }

    // Check if any records were updated
    if (data && data.length > 0) {
      return { success: true, message: 'Bill of lading URL updated successfully' };
    } else {
      return { success: false, message: 'No records found with the provided load_id' };
    }
  } catch (error) {
    console.error('Error updating bill of lading url:', error.message);
    return { success: false, error: error.message };
  }
};

const updateLoadField = async (load_id, fieldToUpdate, fieldValue) => {
  try {
    // Create an object with the dynamic field and value to update
    const updateObject = { [fieldToUpdate]: fieldValue };

    // Update the corresponding record
    const { data, error } = await supabase
      .from('loads')
      .update(updateObject)
      .eq('load_id', load_id);

    if (error) {
      console.error(`Error updating ${fieldToUpdate} of load:`, error);
      return { success: false, error: error.message };
    }

    // Check if any records were updated
    if (data && data.length > 0) {
      return { success: true, message: `Load ${fieldToUpdate} updated successfully` };
    } else {
      return { success: false, message: `No records found with the provided load_id` };
    }
  } catch (error) {
    console.error(`Error updating ${fieldToUpdate} of load:`, error);
    return { success: false, error: error.message };
  }
};


// Export all the functions
export {getLoads, getSingleLoad, getCurrentDriverLoads, getCurrentShipperLoads, updateLoadStatus, updateLoadImageUrl, updateRateConfirmationUrl, updateBillOfLadingUrl, updateLoadField}
