import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from "react-router-dom";
import Home from "./views/home/Home";
import Login from "./views/login/Login";
import About from "./views/about/About";
import Privacy from "./views/privacy/Privacy";
import Dashboard from "./views/dashboard/Dashboard";
import DriverRequest from "./views/driverrequest/DriverRequest";
import LoadDetails from "./views/loaddetails/loadDetails";
import EditLoad from "./components/editload/EditLoad";
import ProtectedRoute from "./ProtectedRoute";
import { UserContext, UserProvider } from "./context/userContext";
import SignUp from "./views/signup/SignUp";
import truckingImage from "./images/Logo_Color.svg";
import UserAccount from "./views/useraccount/userAccount";
import Pricing from "./views/pricing/Pricing";
import Inbox from "./components/inbox/Inbox";
import ChatScreen from "./views/chat/chatScreen";
import Review from "./views/review/Review";
import UserReviews from "./views/userreviews/UserReviews";
import { getCurrentUserProfile } from "./supabase/profile";
import genericAccountIcon from "./images/account_icon.png";
import ProfileDropdown from "./components/profileDropdown/ProfileDropdown";
import CreateLoad from "./components/createload/CreateLoad";
import Search from "./components/search/Search";
import MyLoads from "./components/myLoads/MyLoads";
import Account from "./components/account/Account";
import CompletedLoads from "./components/completedloads/CompletedLoads";

function Header() {
  const location = useLocation();
  const isProtectedRoute =
    location.pathname.startsWith("/dashboard") ||
    location.pathname.startsWith("/loads-complete") ||
    location.pathname.startsWith("/inbox") ||
    location.pathname.startsWith("/search") ||
    location.pathname.startsWith("/new_load") ||
    location.pathname.startsWith("/load-details") ||
    location.pathname.startsWith("/edit-load") ||
    location.pathname.startsWith("/driver-request") ||
    location.pathname.startsWith("/chat") ||
    location.pathname.startsWith("/user-account") ||
    location.pathname.startsWith("/user-reviews") ||
    location.pathname.startsWith("/review");

  const { userId } = useContext(UserContext);
  const [userAvatarImg, setUserAvatarImg] = useState("");
  const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState(false);

  useEffect(() => {
    // setIsLoading(true);
    if (userId) {
      getCurrentUserProfile(userId)
        .then((profileData) => {
          // console.log(profileData[0].avatar_url);
          profileData[0].avatar_url
            ? setUserAvatarImg(() => profileData[0].avatar_url)
            : setUserAvatarImg(genericAccountIcon);
        })
        .catch((error) => {
          console.error("Error fetching user profile avatar:", error);
        });
    }

    // setIsLoading(false);
  }, [userId]);

  useEffect(() => {
    handleDropDownMenuState(true);
  }, [location]);

  const handleDropDownMenuState = (isRouteChange = false) => {
    setIsDropdownMenuOpen(() => (isRouteChange ? false : !isDropdownMenuOpen));
  };

  if (isProtectedRoute) {
    return (
      <header>
        <nav>
          <div>
            {userId && (
              <Link to={"/dashboard"}>
                <img className="header_image" src={truckingImage} alt="Trucking Company" />
              </Link>
            )}
          </div>
          <div>
            {userId && (
              <img
                className="header_user_avatar"
                onClick={() => handleDropDownMenuState()}
                src={userAvatarImg}
                alt="Menu"
              />
            )}
          </div>
        </nav>
        <ProfileDropdown
          isDropdownOpen={isDropdownMenuOpen}
          handleDropDownMenuState={handleDropDownMenuState}
        />
      </header>
    );
  } else {
    return (
      <header>
        <nav>
          <div>
            <Link to={"/"}>
              <img className="header_image" src={truckingImage} alt="Trucking Company" />
            </Link>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              {!userId && (
                <li>
                  <Link to="/login">Login</Link>
                </li>
              )}
              <li>
                <Link to="/pricing">Pricing</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
            </ul>
          </div>
          <div>
            {userId && (
              <img
                className="header_user_avatar"
                onClick={() => handleDropDownMenuState()}
                src={userAvatarImg}
                alt="Menu"
              />
            )}
          </div>
        </nav>
        {userId && (
          <ProfileDropdown
            isDropdownOpen={isDropdownMenuOpen}
            handleDropDownMenuState={handleDropDownMenuState}
          />
        )}
      </header>
    );
  }
}

function App() {
  return (
    <UserProvider>
      <Router>
        <div>
          <Header />
          <main>
            <Routes>
              <Route path="/" exact element={<Home />} />
              <Route path="/login" exact element={<Login />} />
              <Route path="/signup" exact element={<SignUp />} />
              <Route path="/about" exact element={<About />} />
              <Route path="/privacy" exact element={<Privacy />} />
              <Route path="/pricing" exact element={<Pricing />} />

              {/* Nested Routes */}
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute>
                    <Dashboard>
                      <Account />
                      <MyLoads />
                    </Dashboard>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/loads-complete"
                element={
                  <ProtectedRoute>
                    <Dashboard>
                      <Account />
                      <CompletedLoads />
                    </Dashboard>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/inbox"
                element={
                  <ProtectedRoute>
                    <Dashboard>
                      <Inbox />
                    </Dashboard>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/search"
                element={
                  <ProtectedRoute>
                    <Dashboard>
                      <Search />
                    </Dashboard>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/new_load/:loadId?"
                element={
                  <ProtectedRoute>
                    <Dashboard>
                      <CreateLoad />
                    </Dashboard>
                  </ProtectedRoute>
                }
              />
              {/* <Route
                path="/invoices"
                element={
                  <ProtectedRoute>
                    <Dashboard>
                    {"INVOICES"}
                    </Dashboard>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/reports"
                element={
                  <ProtectedRoute>
                    <Dashboard>
                    {"REPORTS"}
                    </Dashboard>
                  </ProtectedRoute>
                }
              /> */}

              <Route
                path="/load-details/:loadId"
                element={
                  <ProtectedRoute>
                    <Dashboard>
                      <LoadDetails />
                    </Dashboard>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/edit-load/:loadId"
                element={
                  <ProtectedRoute>
                    <Dashboard>
                      <EditLoad />
                    </Dashboard>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/driver-request/:driverId"
                element={
                  <ProtectedRoute>
                    <Dashboard>
                      <DriverRequest />
                    </Dashboard>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/chat"
                element={
                  <ProtectedRoute>
                    <Dashboard>
                      <ChatScreen />
                    </Dashboard>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/review/:recipientId/:loadId/:isDriver"
                element={
                  <ProtectedRoute>
                    <Dashboard>
                      <Review />
                    </Dashboard>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/user-account"
                element={
                  <ProtectedRoute>
                    <Dashboard>
                      <UserAccount />
                    </Dashboard>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/user-reviews"
                element={
                  <ProtectedRoute>
                    <Dashboard>
                      <UserReviews />
                    </Dashboard>
                  </ProtectedRoute>
                }
              />
            </Routes>
          </main>
          <footer>
            <p>
              &copy; 2023 OCSC. All rights reserved.{" "}
              <Link className="privacy-link" to="/privacy">
                Privacy Policy
              </Link>
            </p>
          </footer>
        </div>
      </Router>
    </UserProvider>
  );
}

export default App;
