// Author: Ryan Pinkney
// Establish API connection with our supabase project

import { createClient } from "@supabase/supabase-js";

// Set the url and api keys to connect to supabase
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;

export const supabase = createClient(supabaseUrl, supabaseKey);
