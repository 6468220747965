// David Schraedel
// query db, return loads based on params from a driver's search

import { supabase } from "./supabase";

// get all loads that match given search parameterrs
const searchLoads = async (
  { originDate, destinationDate, originCity, originState, destinationCity, destinationState },
  page = 1
) => {
  const numItems = 10;
  let pageNum = (page - 1) * numItems;

  // build query based on params, return unassigned loads, limit to pagination range
  let query = supabase
    .from("loads")
    .select("*")
    .eq("load_status", "new")
    .range(pageNum, pageNum + numItems - 1);

  if (originDate) {
    query = query.gte("pickup_date", originDate);
  }
  if (destinationDate) {
    query = query.lte("delivery_date", destinationDate);
  }
  if (originCity) {
    query = query.eq("origin_address_city", originCity);
  }
  if (originState) {
    query = query.eq("origin_address_state", originState);
  }
  if (destinationCity) {
    query = query.eq("destination_address_city", destinationCity);
  }
  if (destinationState) {
    query = query.eq("destination_address_state", destinationState);
  }

  // call with query
  const { data: loaddata, error: loaderror } = await query;

  if (loaderror) {
    console.error("Error fetching data:", loaderror);
    return;
  } else {
    return loaddata;
  }
};

export { searchLoads };
