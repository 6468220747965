import React, { useEffect, useState, useCallback, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { IoArrowBack, IoSettings, IoClose } from "react-icons/io5";
import { supabase } from "../../supabase/supabase";
import { UserContext } from "../../context/userContext";
import { removeGroupMember } from "../../supabase/groupMembership";
import "./chatScreen.css";

const ChatScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { group_id, group_name } = location.state;
  const { userId } = useContext(UserContext);
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState("");
  const [overlayVisibility, setOverlayVisibility] = useState(false);
  const [showTimestamps, setShowTimestamps] = useState(false);

  const fetchMessages = useCallback(async () => {
    const { data: initialMessages, error } = await supabase
      .from("messages")
      .select("*, profiles!messages_sender_id_fkey(username)")
      .eq("group_id", group_id);

    if (error) console.log("error", error);
    else setMessages(initialMessages);
  }, [group_id]);

  useEffect(() => {
    fetchMessages();
    const messageSubscription = supabase
      .channel("custom-all-channel")
      .on(
        "postgres_changes",
        { event: "*", schema: "public", table: "messages" },
        async (payload) => {
          if (payload.new.group_id === group_id) {
            const { data: userProfile, error } = await supabase
              .from("profiles")
              .select("username")
              .eq("id", payload.new.sender_id);

            if (error) {
              console.error("Error fetching user profile:", error);
            } else {
              const messageWithUserProfile = {
                ...payload.new,
                profiles: userProfile[0],
              };
              setMessages((currentMessages) => [...currentMessages, messageWithUserProfile]);
            }
          }
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(messageSubscription);
    };
  }, [group_id, fetchMessages]);

  const sendMessage = async () => {
    if (inputText.trim()) {
      const newMessage = {
        content: inputText,
        group_id: group_id,
        sender_id: userId,
        message_type: "TEXT",
        created_at: new Date(),
      };

      const { error } = await supabase.from("messages").insert([newMessage]);
      if (error) console.error("Error sending message:", error);

      const { error: groupError } = await supabase
        .from("group_membership")
        .update({ unread: true })
        .eq("group_id", group_id)
        .neq("user_id", userId);

      if (groupError) console.error("Error updating group unread status:", groupError);

      setInputText("");
    }
  };

  const formatTimestamp = (timestampString) => {
    const date = new Date(timestampString);
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;

    return `${hours}:${minutes} ${ampm}`;
  };

  const handleOverlay = () => {
    setOverlayVisibility(!overlayVisibility);
  };

  const handleLeaveGroup = async () => {
    handleOverlay();
    await removeGroupMember(group_id, userId);
    navigate("/dashboard"); // user back to dashboard
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      sendMessage();
    }
  };

  const toggleTimestamps = () => {
    setShowTimestamps(!showTimestamps);
  };

  return (
    <div className="chatScreen">
      <div className="groupNameContainer">
        <button className="backButton" onClick={() => navigate("/inbox")}>
          <IoArrowBack size={30} color="#26475F" />
        </button>
        <span className="groupName">{group_name}</span>
        <button className="settingsButton" onClick={handleOverlay}>
          <IoSettings size={30} color="#26475F" />
        </button>
      </div>
      <div className="messagesContainer">
        {messages
          .slice()
          .reverse()
          .map((item) => (
            <div key={item.id} className="messageContainer">
              <span className={item.sender_id === userId ? "myUsername" : "username"}>
                {item.profiles?.username}
              </span>
              <div className={item.sender_id === userId ? "myMessageBox" : "messageBox"}>
                <div className="messageContent">
                  <span className="messageText">{item.content}</span>
                </div>
              </div>
              {showTimestamps && (
                <span
                  className={
                    item.sender_id === userId ? "myMessageTextTimeStamp" : "messageTextTimeStamp"
                  }>
                  {formatTimestamp(item.created_at)}
                </span>
              )}
            </div>
          ))}
      </div>
      <div className="inputContainer">
        <input
          className="input"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          placeholder="Type a message..."
          onKeyDown={handleKeyDown}
        />
        <button className="sendButton" onClick={sendMessage}>
          Send
        </button>
      </div>
      {overlayVisibility && (
        <div className="overlay">
          <div className="overlayHeader">
            <span className="settingsTitle">Settings</span>
            <button className="closeButton" onClick={handleOverlay}>
              <IoClose size={30} color="#26475F" />
            </button>
          </div>
          <div className="overlayContent">
            <span className="settingsText">Group Name: {group_name}</span>
            {group_name !== "Lanes 🚚" && (
              <button className="leaveGroupButton" onClick={handleLeaveGroup}>
                Leave Group
              </button>
            )}
            <button className="toggleTimestampButton" onClick={toggleTimestamps}>
              {showTimestamps ? "Hide Timestamps" : "Show Timestamps"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatScreen;
