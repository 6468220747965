// Author: Kyle Klintworth
// Allows the user to upload an avater image to the server. Kyle made modification to make this compatible with the web.

import { supabase } from "./supabase";

// Function to upload avatar to Supabase storage bucket
const uploadAvatar = async (file, user_id) => {
  // List existing files in the user's folder and delete them
  const { data: filesToDelete, error: listError } = await supabase.storage
    .from("avatars")
    .list(`${user_id}/`);

  if (listError) {
    console.error("Error listing files:", listError.message);
    return;
  }

  for (const existingFile of filesToDelete) {
    await supabase.storage
      .from("avatars")
      .remove([`${user_id}/${existingFile.name}`]);
  }

  if (!file) {
    console.log("Error", "Please provide a file before uploading.");
    return;
  }

  const fileName = file.name;

  // Upload the new file
  try {
    const { data, error } = await supabase.storage
      .from("avatars")
      .upload(`${user_id}/${fileName}`, file, {
        contentType: file.type,
        upsert: true,
        cacheControl: "3600",
      });

    if (error) {
      throw error;
    }

    console.log("Success", "Photo uploaded successfully!");
  } catch (error) {
    console.error("Error uploading to Supabase:", error.message);
    console.log("Error", "Failed to upload photo. Please try again.");
  }
};

// Function to get public URL of the uploaded avatar
async function getAvatarPublicURL(file, user_id) {
  if (!file) {
    console.log("Error", "We did not receive the file parameter");
    return null;
  }

  const fileName = file.name;
  try {
    const { data, error } = await supabase.storage
      .from("avatars")
      .getPublicUrl(`${user_id}/${fileName}`);

    if (error) {
      console.error("Error generating public URL:", error.message);
      return null;
    }

    return data.publicUrl;
  } catch (err) {
    console.error("Error:", err.message);
    return null;
  }
}

// Function to update profile with new avatar URL
async function updateProfileWithImageURL(imageURL, userID) {
  try {
    if (!imageURL) {
      console.error("Error: imageURL parameter is missing.");
      return null;
    }

    const { data, error } = await supabase
      .from("profiles")
      .update({ avatar_url: imageURL })
      .eq("id", userID);

    if (error) {
      throw error;
    }

    console.log("Profile updated successfully with image URL:", imageURL);
    return data;
  } catch (error) {
    console.error("Error updating profile with image URL:", error.message);
    return null;
  }
}

export { uploadAvatar, getAvatarPublicURL, updateProfileWithImageURL };
