// create load, supabase
// You can add more fields in the createLoad method to match whatever fields you want to
// add to the database when initially creating a load. The current fields should be the minimum fields
// required to create a load.
// Tyler Steele
import { supabase } from "./supabase";
import { createUserLoad } from "./userloads";

const createLoad = async (
  userId,
  {
    cargoDescription,
    cargoCategory,
    totalRate,
    weightLb,
    lengthFt,
    widthFt,
    heightFt,
    equipmentType,
    specialInstructions,
    pickupDate = null,
    deliveryDate = null,
    pickupTime,
    pickupAddressStreet,
    pickupAddressCity,
    pickupAddressState,
    pickupAddressZip,
    deliveryAddressStreet,
    deliveryAddressCity,
    deliveryAddressState,
    deliveryAddressZip,
    palletCount,
    pieceCount,
    hazmatLicenseRequired,
    doublesEndorsementRequired,
    tankerEndorsementRequired,
    twicRequired,
    tsaCertifiedRequired,
  }
) => {
  //Generate a 4-digit pin
  const generatePin = () => Math.floor(1000 + Math.random() * 9000);

  var pickupDateGood = null;
  if (pickupDate) {
    const pickupISODateString = pickupDate.toISOString();
    pickupDateGood = pickupISODateString.split("T")[0];
  }

  var deliveryDateGood = null;
  if (deliveryDate) {
    let deliveryISODateString = deliveryDate.toISOString();
    deliveryDateGood = deliveryISODateString.split("T")[0];
  }

  let pickupTimeGood = pickupTime;
  if (!pickupTime) {
    pickupTimeGood = null;
  }

  // Use the google maps api to calculate the distance between the addresses
  // ****************************************************************
  // const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API; // Replace with your Google Maps API key
  // const apiUrl = `https://maps.googleapis.com/maps/api/directions/json?origin=${encodeURIComponent(
  //   origin
  // )}&destination=${encodeURIComponent(destination)}&key=${apiKey}`;

  // let mapsCalcMiles;
  // console.log("origin:", typeof origin, ",", origin);
  // console.log("destination:", typeof destination, ",", destination);
  // console.log("apiKey:", typeof apiKey, ",", apiKey);
  // console.log("apiUrl:", typeof apiUrl, ",", apiUrl);
  // try {
  //   const response = await fetch(apiUrl);
  //   const data = await response.data();

  //   console.log("data:", typeof data, ",", data);
  //   console.log("status:", typeof data.status, ",", data.status);

  //   if (data.status === "OK") {
  //     const distance = data.routes[0].legs[0].distance.text;
  //     console.log("Distance: ", distance);
  //     mapsCalcMiles = distance.replace(",", "");
  //     mapsCalcMiles = mapsCalcMiles.slice(0, -3);
  //   } else {
  //     console.error("Error fetching distance:", data.status);
  //   }
  // } catch (error) {
  //   console.error("Error fetching data:", error);
  // }
  // console.log("test:", typeof mapsCalcMiles, ",", mapsCalcMiles);
  //  ****************************************************************

  // Call the Supabase edge function to calculate the distance
  const origin = `${pickupAddressStreet}, ${pickupAddressCity}, ${pickupAddressState} ${pickupAddressZip}`;
  const destination = `${deliveryAddressStreet}, ${deliveryAddressCity}, ${deliveryAddressState} ${deliveryAddressZip}`;
  let googleCalculatedMiles; // Declare the variable outside the try block

  try {
    const { data, error } = await supabase.functions.invoke("distance-engine", {
      body: { origin: origin, destination: destination },
    });

    if (error) {
      throw error;
    }

    googleCalculatedMiles = data.distance; // Assign value to the variable
  } catch (error) {
    console.log("There was an issue with the distance engine:", error);
  }

  //Set verification pin
  const verificationPin = generatePin();

  // "round" or format function for rate/mile
  var formatterUSD = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" });

  // Make the supabase call to update the record
  const { data, error } = await supabase
    .from("loads")
    .insert([
      {
        cargo_description: cargoDescription,
        category: cargoCategory,
        pickup_date: pickupDateGood,
        delivery_date: deliveryDateGood,
        pickup_time: pickupTimeGood,
        origin_address_street: pickupAddressStreet,
        origin_address_city: pickupAddressCity,
        origin_address_state: pickupAddressState,
        origin_address_zip: pickupAddressZip,
        destination_address_street: deliveryAddressStreet,
        destination_address_city: deliveryAddressCity,
        destination_address_state: deliveryAddressState,
        destination_address_zip: deliveryAddressZip,
        total_rate: Number(totalRate),
        weight_lb: isNaN(parseInt(weightLb)) ? null : weightLb,
        length_ft: isNaN(parseInt(lengthFt)) ? null : lengthFt,
        width_ft: isNaN(parseInt(widthFt)) ? null : widthFt,
        height_ft: isNaN(parseInt(heightFt)) ? null : heightFt,
        equipment_type: equipmentType,
        special_instructions: specialInstructions,
        pallet_count: isNaN(parseInt(palletCount)) ? null : palletCount,
        piece_count: isNaN(parseInt(pieceCount)) ? null : pieceCount,
        posted: new Date().toISOString().split("T")[0],
        load_status: "new",
        google_maps_calculated_miles: googleCalculatedMiles,
        rate_per_mile: Number(
          formatterUSD
            .format(Number(totalRate) / Number(googleCalculatedMiles))
            .replace(",", "")
            .replace("$", "")
        ),
        required_hazmat_license: hazmatLicenseRequired,
        required_doubles_endorsement: doublesEndorsementRequired,
        required_tanker_endorsement: tankerEndorsementRequired,
        required_twic: twicRequired,
        required_tsa_certified: tsaCertifiedRequired,
        verification_pin: verificationPin,
      },
    ])
    .select();

  if (error) {
    console.error("Error adding load:", error);
    return;
  }

  // create userload
  const loadId = data[0].load_id;
  const relationshipType = "shipper";
  createUserLoad(userId, loadId, relationshipType);

  return data;
};

// Export all the functions
export { createLoad };
