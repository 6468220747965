import React, { useEffect, useState, useContext } from "react";
import "./fileUploadModal.css";
import { UserContext } from "../../context/userContext";
import { uploadFileUserBucket } from "../../supabase/userfilesbucket";
import { updateDocumentUrl } from "../../supabase/profile";

const FileUploadModal = ({ isOpen, onClose }) => {
  const { userId } = useContext(UserContext);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setSelectedOption(null);
      setSelectedFile(null);
    }
  }, [isOpen]);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setSelectedFile(null);
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleUploadFile = async () => {
    if (!selectedFile || !selectedOption) {
      alert("Please select a file and an option before uploading.");
      return;
    }

    setIsUploading(true);

    let columnToUpdate = "";
    switch(selectedOption) {
      case "insurance":
        columnToUpdate = "insurance_url";
        break;
      case "mc_cert":
        columnToUpdate = "mc_cert_url";
        break;
      case "w4_form":
        columnToUpdate = "w4_url";
        break;
      default:
        break;
    }

    try {
      const publicUrl = await uploadFileUserBucket(
        selectedFile,
        userId,
        columnToUpdate
      );
      if (publicUrl) {
        updateDocumentUrl(userId, publicUrl, columnToUpdate);
        console.log(`File uploaded successfully: ${publicUrl}`);
        alert("File uploaded successfully!");
      } else {
        console.error("Failed to upload file.");
        alert("Failed to upload file. Please try again.");
      }
      setIsUploading(false);
      onClose();
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("Error uploading file. Please try again.");
      setIsUploading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modal-close" onClick={onClose}>
          &times;
        </button>
        <h2>Upload File</h2>
        <div className="modal-options">
          <button
            className={`modal-option ${
              selectedOption === "Insurance" ? "selected" : ""
            }`}
            onClick={() => handleOptionClick("insurance")}
          >
            Insurance
          </button>
          <button
            className={`modal-option ${
              selectedOption === "MC Certificate" ? "selected" : ""
            }`}
            onClick={() => handleOptionClick("mc_cert")}
          >
            MC Certificate
          </button>
          <button
            className={`modal-option ${
              selectedOption === "W4 Form" ? "selected" : ""
            }`}
            onClick={() => handleOptionClick("w4_form")}
          >
            W4 Form
          </button>
        </div>
        {selectedOption && (
          <div className="file-upload-section">
            <h3>Upload {selectedOption}</h3>
            <input type="file" onChange={handleFileChange} />
            <button
              onClick={handleUploadFile}
              className="upload-button"
              type="button"
              disabled={isUploading}
            >
              {isUploading ? "Uploading..." : "Upload File"}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default FileUploadModal;
