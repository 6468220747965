// Author: Ryan Pinkney & Stetson Kent & Kyle Klimtworth
// Define all the functions that interact with all our data tables
import { supabase } from "./supabase";

const getCurrentUserProfile = async (id) => {
  // If load_id is provided, fetch only the specific load
  if (id) {
    // Run a query that returns the profile of the user that equals id
    const { data: profiledata, error: loadError } = await supabase
      .from("profiles")
      .select("*")
      .eq("id", id);

    if (loadError) {

      console.error("Error fetching profile details:", loadError);

      return;
    } else {
      return profiledata;
    }
  }
};

// Function used to update the document url in the users table
const updateDocumentUrl = async (user_id, url, columnToUpdate) => {
  try {
    if (!url || !columnToUpdate) {
      console.log("Error updating document url: URL is undefined");
      return { success: false, error: "URL is undefined" };
    }

    // Construct the update object dynamically based on the columnToUpdate
    const updateObject = {};
    updateObject[columnToUpdate] = url;

    // Update the document url of the corresponding record
    const { data, error } = await supabase
      .from("profiles")
      .update(updateObject)
      .eq("id", user_id);


    if (error) {
      console.error("Error updating document url:", error.message);
      return { success: false, error: error.message };
    }

    // Check if any records were updated
    if (data && data.length > 0) {

      return {
        success: true,
        message: "${columnToUpdate} URL updated successfully",
      };
    } else {
      return {
        success: false,
        message: "No records found with the provided user_id",
      };

    }
  } catch (error) {
    console.error("Error updating document url:", error.message);
    return { success: false, error: error.message };
  }
};

// Update user profile in Supabase
const editUserAccount = async (
  userId,
  {
    username,
    full_name,
    phone_number,
    equipment_type,
    max_length,
    max_weight,
    hazmat_license,
    tanker_endorsement,
    doubles_endorsement,
    twic_card,
    max_insurance_coverage,
  }
) => {
  try {
    const { data, error } = await supabase
      .from("profiles")
      .update({
        username: username,
        full_name: full_name,
        phone_number: phone_number,
        equipment_type: equipment_type,
        max_length: max_length,
        max_weight: max_weight,
        hazmat_license: hazmat_license,
        tanker_endorsement: tanker_endorsement,
        doubles_endorsement: doubles_endorsement,
        twic_card: twic_card,
        max_insurance_coverage: max_insurance_coverage,
        updated_at: new Date().toISOString(),
      })
      .eq("id", userId);

    if (error) {
      console.log("Error Updating user account");
      throw new Error(error.message);
    }
    return data;
  } catch (error) {
    console.log("Error updating user account:", error);
    console.error("Error updating user account:", error);
    throw error;
  }
};

// Export functions here
export { getCurrentUserProfile, updateDocumentUrl, editUserAccount };

