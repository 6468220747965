// Author Ryan Pinkney
// This component is onyl visible to drivers and allows them to search for loads and request them.

import React, { useState } from "react";
import "./Search.css";
import SearchResults from "./SearchResults";
import { searchLoads } from "../../supabase/searchLoads";
import { getStateCodes } from "../../utils/getStateCodes";

function Search() {
  const [formData, setFormData] = useState({
    originDate: "",
    destinationDate: "",
    originCity: "",
    originState: "",
    destinationCity: "",
    destinationState: "",
  });
  const [loadData, setLoadData] = useState(null);
  const stateAbbreviationList = getStateCodes();
  const [pageNum, setPageNum] = useState(1);

  const handlePageChange = (pageAddition = 0) => {
    let newPage = Number(pageNum) + Number(pageAddition);
    setPageNum(newPage);

    searchLoads(formData, newPage)
      .then((loadData) => {
        setLoadData(() => loadData);
      })
      .catch((error) => {
        console.error("Error fetching load data:", error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // get loads from supabase function
    searchLoads(formData)
      .then((loadData) => {
        setLoadData(() => loadData);
      })
      .catch((error) => {
        console.error("Error fetching load data:", error);
      });
  };

  return (
    <>
      <div className="search-form">
        <h2>Search for Loads</h2>
        <br></br>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="originDate">Origin Date:</label>
            <input
              type="date"
              id="originDate"
              name="originDate"
              value={formData.originDate}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="destinationDate">Destination Date:</label>
            <input
              type="date"
              id="destinationDate"
              name="destinationDate"
              value={formData.destinationDate}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="originCity">Origin City:</label>
            <input
              type="text"
              id="originCity"
              name="originCity"
              value={formData.originCity}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="originState">Pickup State</label>
            <select
              value={formData.originState}
              onChange={handleChange}
              id="originState"
              name="originState">
              <option value={""}></option>
              {stateAbbreviationList.map((state) => {
                return (
                  <option key={state} value={state}>
                    {state}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="destinationCity">Destination City:</label>
            <input
              type="text"
              id="destinationCity"
              name="destinationCity"
              value={formData.destinationCity}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="destinationState">Destination State</label>
            <select
              value={formData.destinationState}
              onChange={handleChange}
              id="destinationState"
              name="destinationState">
              <option value={""}></option>
              {stateAbbreviationList.map((state) => {
                return (
                  <option key={state} value={state}>
                    {state}
                  </option>
                );
              })}
            </select>
          </div>
          <button className="search_button" type="submit">
            Search
          </button>
        </form>
      </div>
      <SearchResults searchResults={loadData} changePage={handlePageChange} page={pageNum} />
    </>
  );
}

export default Search;
