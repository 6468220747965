// Author Kyle Klintworth

import React, { useState, useContext, useEffect } from "react";
import "./userAccount.css";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/userContext";
import { editUserAccount, getCurrentUserProfile } from "../../supabase/profile";
import { uploadAvatar, getAvatarPublicURL, updateProfileWithImageURL } from "../../supabase/avatar";
import account_icon from "../../images/account_icon.png";
import FileUploadModal from "../../components/fileUploadModal/fileUploadModal";

const UserAccount = () => {
  const navigate = useNavigate();
  const { userId } = useContext(UserContext);
  const [userData, setUserData] = useState(null);
  const [editedData, setEditedData] = useState(null);
  const [isEdited, setIsEdited] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (userId) {
      getCurrentUserProfile(userId)
        .then((profileData) => {
          setUserData(profileData[0]);
          setEditedData(profileData[0]);
        })
        .catch((error) => {
          console.error("Error fetching user profile:", error);
          setErrorMessage("Failed to load user profile.");
        });
    }
  }, [userId, reload]);

  const handleBackNavigation = () => {
    navigate(-1); // user back to their previous location
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedData({ ...editedData, [name]: value });
    setIsEdited(true);
  };

  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    setEditedData({ ...editedData, [name]: value === "true" });
    setIsEdited(true);
  };

  const validateFields = () => {
    if (!editedData.username || !editedData.full_name || !editedData.phone_number) {
      return "Please fill out all required fields.";
    }
    return "";
  };

  const handleSaveChanges = async () => {
    const validationError = validateFields();
    if (validationError) {
      setErrorMessage(validationError);
      return;
    }

    setIsLoading(true);
    setErrorMessage("");

    try {
      let avatarUrl = userData.avatar_url;

      if (profileImage) {
        await uploadAvatar(profileImage, userId);
        avatarUrl = await getAvatarPublicURL(profileImage, userId);
        if (!avatarUrl) {
          throw new Error("Failed to generate public URL for the uploaded avatar.");
        }

        await updateProfileWithImageURL(avatarUrl, userId);
      }

      const updatedData = {
        ...editedData,
        avatar_url: avatarUrl,
      };
      await editUserAccount(userId, updatedData);

      setReload((prev) => !prev);
      setIsEdited(false);
    } catch (error) {
      console.error("Error updating user account:", error);
      setErrorMessage("An error occurred while updating your account.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setProfileImage(file);
      setIsEdited(true);
    } else {
      setErrorMessage("Please upload a valid image file.");
    }
  };

  const handleAddFiles = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="back-button-container-account">
        <button className="back-button" onClick={handleBackNavigation}>
          &lt;
        </button>
      </div>

      {userData ? (
        <div className="user-account">
          <div className="user-account-form">
            <form>
              <h1>Account Details</h1>
              <div className="avatar-form-group">
                <img
                  src={userData.avatar_url ? userData.avatar_url : account_icon}
                  alt={userData.avatar_url ? "User Image" : "Account Icon"}
                />

                <input
                  type="file"
                  onChange={handleImageUpload}
                  placeholder="Replace Profile Image"
                />
              </div>

              <div className="account-form-group">
                <label htmlFor="fullName">Full Name:</label>
                <input
                  id="fullName"
                  type="text"
                  name="full_name"
                  value={editedData.full_name}
                  onChange={handleInputChange}
                />
              </div>
              <div className="account-form-group">
                <label htmlFor="username">Username:</label>
                <input
                  id="username"
                  type="text"
                  name="username"
                  value={editedData.username}
                  onChange={handleInputChange}
                />
              </div>
              <div className="account-form-group">
                <label htmlFor="phoneNum">Phone Number:</label>
                <input
                  id="phoneNum"
                  type="text"
                  name="phone_number"
                  value={editedData.phone_number}
                  onChange={handleInputChange}
                />
              </div>
              {userData.user_type === "driver" && (
                <>
                  <div className="account-form-group">
                    <label htmlFor="maxLength">Max Length:</label>
                    <input
                      id="maxLength"
                      type="number"
                      name="max_length"
                      value={editedData.max_length || ""}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="account-form-group">
                    <label htmlFor="maxWeight">Max Weight:</label>
                    <input
                      id="maxWeight"
                      type="number"
                      name="max_weight"
                      value={editedData.max_weight || ""}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="account-form-group">
                    <label htmlFor="maxInsurance">Maximum Insurance Coverage:</label>
                    <input
                      id="maxInsurance"
                      type="number"
                      name="max_insurance_coverage"
                      value={editedData.max_insurance_coverage || ""}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="account-form-group">
                    <label htmlFor="equipment">Equipment Type:</label>
                    {/* <input
                      id="equipment"
                      type="text"
                      name="equipment_type"
                      value={editedData.equipment_type || ""}
                      onChange={handleInputChange}
                    /> */}
                    <select
                      value={editedData.equipment_type || ""}
                      onChange={handleInputChange}
                      id="equipment"
                      name="equipment_type">
                      <option value={""} disabled={true}>
                        Select Equipment Type
                      </option>
                      <option value={"AUTO"}>Auto Carrier</option>
                      <option value={"B-TR"}>B-Train</option>
                      <option value={"DUMP"}>Dump Trailer</option>
                      <option value={"F"}>Flatbed</option>
                      <option value={"SD"}>Step Deck</option>
                      <option value={"HOPP"}>Hopper Bottom</option>
                      <option value={"LBO_RGN"}>Lowboy or RGN</option>
                      <option value={"R"}>Reefer</option>
                      <option value={"RGN"}>Removable Gooseneck</option>
                      <option value={"TANK"}>Tanker</option>
                      <option value={"V"}>Van</option>
                      <option value={"OTHER"}>Other</option>
                    </select>
                  </div>

                  <div className="radio-groups-container">
                    <div className="radio-group">
                      <label>Hazmat Endorsement:</label>
                      <div>
                        <label>
                          <input
                            type="radio"
                            name="hazmat_license"
                            value={true}
                            checked={editedData.hazmat_license === true}
                            onChange={handleRadioChange}
                          />
                          Yes
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="hazmat_license"
                            value={false}
                            checked={editedData.hazmat_license === false}
                            onChange={handleRadioChange}
                          />
                          No
                        </label>
                      </div>
                    </div>
                    <div className="radio-group">
                      <label>Tanker Endorsement:</label>
                      <div>
                        <label>
                          <input
                            type="radio"
                            name="tanker_endorsement"
                            value={true}
                            checked={editedData.tanker_endorsement === true}
                            onChange={handleRadioChange}
                          />
                          Yes
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="tanker_endorsement"
                            value={false}
                            checked={editedData.tanker_endorsement === false}
                            onChange={handleRadioChange}
                          />
                          No
                        </label>
                      </div>
                    </div>
                    <div className="radio-group">
                      <label>Doubles Endorsement:</label>
                      <div>
                        <label>
                          <input
                            type="radio"
                            name="doubles_endorsement"
                            value={true}
                            checked={editedData.doubles_endorsement === true}
                            onChange={handleRadioChange}
                          />
                          Yes
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="doubles_endorsement"
                            value={false}
                            checked={editedData.doubles_endorsement === false}
                            onChange={handleRadioChange}
                          />
                          No
                        </label>
                      </div>
                    </div>
                    <div className="radio-group">
                      <label>TWIC Card:</label>
                      <div>
                        <label>
                          <input
                            type="radio"
                            name="twic_card"
                            value={true}
                            checked={editedData.twic_card === true}
                            onChange={handleRadioChange}
                          />
                          Yes
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="twic_card"
                            value={false}
                            checked={editedData.twic_card === false}
                            onChange={handleRadioChange}
                          />
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className="user-account-files">
                <h1>Existing files</h1>
                {/* Existing files content */}
                <button className="save-changes-button" onClick={handleAddFiles}>
                  Add Files
                </button>
              </div>

              {errorMessage && <div className="error-message">{errorMessage}</div>}
              {isEdited && (
                <button
                  type="button"
                  className="save-changes-button"
                  onClick={handleSaveChanges}
                  disabled={isLoading}>
                  Save Changes
                  {isLoading && <span className="spinner"></span>}{" "}
                </button>
              )}
            </form>
          </div>
        </div>
      ) : (
        <div className="no-account-info">
          <p>No account information found at this time.</p>
        </div>
      )}
      <FileUploadModal isOpen={isModalOpen} onClose={closeModal} />
    </>
  );
};

export default UserAccount;
