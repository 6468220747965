import React from "react";
import "./Home.css";
import map_1 from "../../images/map_1.jpeg";
import QRCode from "../../images/AppStoreQR.png";
import phone from "../../images/phones.png";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();

  return (
    <div>
      <div
        className="about_section"
        style={{
          marginTop: -50,
          marginBottom: -20,
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: "1px",
          height: 600,
        }}>
        <div className="images">
          <img src={map_1} alt="Trucking Logo" className="map_image" />
        </div>
        <div>
          <p className="about_words">Connecting Drivers and Shippers</p>
          <p className="about_text">
            Lanes revolutionizes logistics, connecting drivers and shippers seamlessly. With
            real-time tracking and secure payments, it ensures efficient and trustworthy
            transactions.
            <br />
            <br />
            <button 
              className="button button1"
              onClick={() => navigate("/signup")}
              >Get Started
            </button>
          </p>
        </div>
      </div>

      <div className="box">
        <p>
          Streamline your operations and elevate your efficiency with Lanes, the ultimate load board, communication, and optimization tool designed specifically for professionals in the trucking, logistics, and supply chain industries.
        </p>
      </div>

      <div className="row">
        <div className="column">
          <h2>Carriers/Owner Operators</h2>
          <p>The lanes solution was built with drivers in mind, providing them with the tools they need to manage loads efficiently. The app's intuitive interface allows drivers to view and book loads, store and send necessary documents, and receive real-time notifications, all from their mobile device.</p>
        </div>
        <div className="column">
          <h2>Freight Brokers</h2>
          <p>Shippers can rely on the lanes solution to find reliable drivers and manage shipments seamlessly. The platform offers comprehensive tools for posting loads, communicating with drivers, and tracking deliveries, ensuring that shipments are handled efficiently and transparently.</p>
        </div>
      </div>

      <div className="questions">
        <h1>Questions?</h1>
        <p className="questions_text">Have any questions or do you want to have a free demo of our products in action?<br />Send us an email with the link below.</p>
        <button 
          className="button button2"
          onClick={ ()=> alert("Feature not available yet") } //Replace with navigation to contact page when available
          >Contact Us
        </button>
      </div>

      <div className="mobile_app">
        <img src={phone} alt="Mobile App" className="mobile_image" />
        <div className="mobile_details">
          <h2 className="mobile_app_header">Mobile App</h2>
          <h3 className="key_features_header">Key Features</h3>
          <div className="key_features_columns">
            <ul className="key_features_list">
              <li><span className="arrow">&#10148;</span> Algorithm-based recommended loads</li>
              <li><span className="arrow">&#10148;</span> Easy load booking</li>
              <li><span className="arrow">&#10148;</span> Intuitive Design</li>
              <li><span className="arrow">&#10148;</span> Real-time tracking (Coming Soon)</li>
            </ul>
            <ul className="key_features_list">
              <li><span className="arrow">&#10148;</span> Document management</li>
              <li><span className="arrow">&#10148;</span> Notifications</li>
              <li><span className="arrow">&#10148;</span> In-app messaging</li>
              <li><span className="arrow">&#10148;</span> Easy filtering</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="app">
        <img src={QRCode} alt="QR code" className="qr_code" />
        <div className="app_details">
          <p className="title">Download the App</p>
        </div>
      </div>
    </div>
  );
}

export default Home;
