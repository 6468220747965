import React, { useState, useContext, useEffect } from "react";
import "./MyLoads.css";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/userContext";
import { getCurrentUserProfile } from "../../supabase/profile";
import { getCurrentShipperLoads, getCurrentDriverLoads } from "../../supabase/loads";
import { getPendingLoadsDriver, getPendingLoadsShipper } from "../../supabase/userloads";
import { IoRefresh } from "react-icons/io5";
import { FaEnvelope, FaFile, FaBox, FaTruck } from "react-icons/fa";

const MyLoads = () => {
  const navigate = useNavigate();
  const { userId } = useContext(UserContext);
  const [loadData, setLoadData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [isShipper, setIsShipper] = useState(false);
  const [isDriver, setIsDriver] = useState(false);
  const [activeTab, setActiveTab] = useState("new"); // 'all' or 'complete'
  const [pendingLoadsDataDriver, setPendingLoadsDriver] = useState([]);
  // const [pendingLoadsDataShipper, setPendingLoadsShipper] = useState([]);

  // Fetch user profile data on component mount
  useEffect(() => {
    if (userId) {
      // Get information about the user so we can determine if they are a shipper or driver
      getCurrentUserProfile(userId)
        .then((userData) => {
          setUserData(() => userData[0]);

          // Display their list of loads depending on whether they are a driver or shipper
          if (userData[0].user_type === "shipper") {
            setIsShipper(() => true);
          } else if (userData[0].user_type === "driver") {
            setIsDriver(() => true);
          } else {
            setIsShipper(() => false);
          }
        })
        .catch((error) => {
          console.error("Error fetching user profile:", error);
        });

      if (isShipper) {
        getCurrentShipperLoads(userId)
          .then((loadData) => {
            setLoadData(() => loadData);
          })
          .catch((error) => {
            console.error("Error fetching load data:", error);
          });
      } else {
        getCurrentDriverLoads(userId)
          .then((loadData) => {
            setLoadData(() => loadData);
          })
          .catch((error) => {
            console.error("Error fetching load data:", error);
          });

        getPendingLoadsDriver(userId)
          .then((loadData) => {
            setPendingLoadsDriver(() => loadData);
          })
          .catch((error) => {
            console.error("Error fetching load data:", error);
          });
      }
    }
  }, [userId, isShipper]);

  const handleRefresh = () => {
    if (isShipper) {
      getCurrentShipperLoads(userId)
        .then((loadData) => {
          setLoadData(loadData);
        })
        .catch((error) => {
          console.error("Error fetching load data:", error);
        });
    } else {
      getCurrentDriverLoads(userId)
        .then((loadData) => {
          setLoadData(loadData);
        })
        .catch((error) => {
          console.error("Error fetching load data:", error);
        });

      getPendingLoadsDriver(userId)
        .then((loadData) => {
          setPendingLoadsDriver(loadData);
        })
        .catch((error) => {
          console.error("Error fetching load data:", error);
        });
    }
  };

  // Filter loads based on status
  const allLoads = loadData
    ? [
        ...loadData,
        ...pendingLoadsDataDriver.filter(
          (pendingLoad) => !loadData.some((load) => load.load_id === pendingLoad.load_id)
        ),
      ]
    : [];
  // const completeLoads = allLoads.filter((load) => load.load_status === "complete");
  // const incompleteLoads = allLoads.filter((load) => load.load_status !== "complete");
  const newLoads = allLoads.filter(
    (load) => load.load_status === "new" || load.load_status === "pending"
  );
  const prePickupLoads = allLoads.filter(
    (load) =>
      load.load_status === "rate confirmation" || load.load_status === "rate confirmation sent"
  );
  const inTransitLoads = allLoads.filter(
    (load) => load.load_status === "pickup" || load.load_status === "in transit"
  );

  // Function to handle row click and navigate to load details page
  const handleRowClick = (loadId) => {
    navigate(`/load-details/${loadId}`);
  };

  // Function to get status icons
  const getStatusIcons = (loadStatus, isDriver, isShipper) => {
    const icons = [];
    if (loadStatus === "rate confirmation") {
      icons.push(<FaFile key="document" />);
    }
    if (loadStatus === "rate confirmation sent") {
      icons.push(<FaEnvelope key="envelope" />);
    }
    if (loadStatus === "pickup") {
      icons.push(<FaBox key="shipping" />);
    }
    if (loadStatus === "in transit") {
      icons.push(<FaTruck key="truck" />);
    }
    return icons;
  };

  // Define a function to check if a notification dot should be displayed
  const hasNotification = (loadStatus, isDriver, isShipper) => {
    // Define the conditions under which a notification dot should appear
    return (
      (loadStatus === "pending" && isShipper) ||
      (loadStatus === "rate confirmation" && isShipper) ||
      (loadStatus === "rate confirmation sent" && isDriver) ||
      (loadStatus === "pickup" && isDriver) ||
      (loadStatus === "in transit" && isDriver)
    );
  };

  const hasPendingAction = (loads) => {
    let hasANotification = false;
    for (let i = 0; i < loads.length; i++) {
      if (hasNotification(loads[i].load_status, isDriver, isShipper)) {
        hasANotification = true;
        break;
      }
    }
    return hasANotification;
  };

  return (
    <>
      {loadData ? (
        <>
          <div className="tabs">
            <div
              className={`${activeTab === "new" ? "active" : ""} ${
                hasPendingAction(newLoads) ? "tab_pending_action" : ""
              }`}
              onClick={() => setActiveTab("new")}>
              New
              {hasPendingAction(newLoads) && (
                <span className="notification_header">&#9679;</span>
              )}
            </div>
            <div
              className={`${activeTab === "pre_pickup" ? "active" : ""} ${
                hasPendingAction(prePickupLoads) ? "tab_pending_action" : ""
              }`}
              onClick={() => setActiveTab("pre_pickup")}>
              Pre-pickup
              {hasPendingAction(prePickupLoads) && (
                <span className="notification_header">&#9679;</span>
              )}
            </div>
            <div
              className={`${activeTab === "in_transit" ? "active" : ""} ${
                hasPendingAction(inTransitLoads) ? "tab_pending_action" : ""
              }`}
              onClick={() => setActiveTab("in_transit")}>
              In Transit
              {hasPendingAction(inTransitLoads) && (
                <span className="notification_header">&#9679;</span>
              )}
            </div>
          </div>
          {activeTab === "new" && (
            <div className="user-profile">
              <div className="table-container-dashboard">
                <table className="table-dashboard">
                  <thead>
                    <tr>
                      <th>Origin</th>
                      <th>Rate</th>
                      <th>Destination</th>
                      <th>
                        Status{" "}
                        <button className="refresh-button" onClick={handleRefresh}>
                          <IoRefresh size={20} />
                        </button>{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {[...newLoads]
                      // Sort by presence of notification dot (reverse so true values come first)
                      .sort((a, b) => {
                        const aHasNotification = hasNotification(
                          a.load_status,
                          isDriver,
                          isShipper
                        );
                        const bHasNotification = hasNotification(
                          b.load_status,
                          isDriver,
                          isShipper
                        );
                        return bHasNotification - aHasNotification;
                      })
                      .map((load, index) => (
                        <tr
                          key={index}
                          className={`pill-row ${load.load_status === "pending" ? "pending" : ""}`}
                          onClick={() => handleRowClick(load.load_id)}>
                          <td>
                            {load.origin_address_city}, {load.origin_address_state}
                          </td>
                          <td>${load.rate_per_mile.toFixed(2)}</td>
                          <td>
                            {load.destination_address_city}, {load.destination_address_state}
                          </td>
                          <td>
                            <div className="load-status-container">
                              <span className="load-status-text">
                                {load.load_status.charAt(0).toUpperCase() +
                                  load.load_status.slice(1)}
                              </span>
                              {getStatusIcons(load.load_status, isDriver, isShipper).map(
                                (icon, idx) => (
                                  <span key={idx} className="icon-container">
                                    {icon}
                                    {hasNotification(load.load_status, isDriver, isShipper) && (
                                      <span className="notification">&#9679;</span>
                                    )}
                                  </span>
                                )
                              )}
                              {!getStatusIcons(load.load_status, isDriver, isShipper).length &&
                                hasNotification(load.load_status, isDriver, isShipper) && (
                                  <span className="notification">&#9679;</span>
                                )}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {activeTab === "pre_pickup" && (
            <div className="user-profile">
              <div className="table-container-dashboard">
                <table className="table-dashboard">
                  <thead>
                    <tr>
                      <th>Origin</th>
                      <th>Rate</th>
                      <th>Destination</th>
                      <th>
                        Status{" "}
                        <button className="refresh-button" onClick={handleRefresh}>
                          <IoRefresh size={20} />
                        </button>{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {[...prePickupLoads]
                      // Sort by presence of notification dot (reverse so true values come first)
                      .sort((a, b) => {
                        const aHasNotification = hasNotification(
                          a.load_status,
                          isDriver,
                          isShipper
                        );
                        const bHasNotification = hasNotification(
                          b.load_status,
                          isDriver,
                          isShipper
                        );
                        return bHasNotification - aHasNotification;
                      })
                      .map((load, index) => (
                        <tr
                          key={index}
                          className={`pill-row ${load.load_status === "pending" ? "pending" : ""}`}
                          onClick={() => handleRowClick(load.load_id)}>
                          <td>
                            {load.origin_address_city}, {load.origin_address_state}
                          </td>
                          <td>${load.rate_per_mile.toFixed(2)}</td>
                          <td>
                            {load.destination_address_city}, {load.destination_address_state}
                          </td>
                          <td>
                            <div className="load-status-container">
                              <span className="load-status-text">
                                {load.load_status.charAt(0).toUpperCase() +
                                  load.load_status.slice(1)}
                              </span>
                              {getStatusIcons(load.load_status, isDriver, isShipper).map(
                                (icon, idx) => (
                                  <span key={idx} className="icon-container">
                                    {icon}
                                    {hasNotification(load.load_status, isDriver, isShipper) && (
                                      <span className="notification">&#9679;</span>
                                    )}
                                  </span>
                                )
                              )}
                              {!getStatusIcons(load.load_status, isDriver, isShipper).length &&
                                hasNotification(load.load_status, isDriver, isShipper) && (
                                  <span className="notification">&#9679;</span>
                                )}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {activeTab === "in_transit" && (
            <div className="user-profile">
              <div className="table-container-dashboard">
                <table className="table-dashboard">
                  <thead>
                    <tr>
                      <th>Origin</th>
                      <th>Rate</th>
                      <th>Destination</th>
                      <th>
                        Status{" "}
                        <button className="refresh-button" onClick={handleRefresh}>
                          <IoRefresh size={20} />
                        </button>{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {[...inTransitLoads]
                      // Sort by presence of notification dot (reverse so true values come first)
                      .sort((a, b) => {
                        const aHasNotification = hasNotification(
                          a.load_status,
                          isDriver,
                          isShipper
                        );
                        const bHasNotification = hasNotification(
                          b.load_status,
                          isDriver,
                          isShipper
                        );
                        return bHasNotification - aHasNotification;
                      })
                      .map((load, index) => (
                        <tr
                          key={index}
                          className={`pill-row ${load.load_status === "pending" ? "pending" : ""}`}
                          onClick={() => handleRowClick(load.load_id)}>
                          <td>
                            {load.origin_address_city}, {load.origin_address_state}
                          </td>
                          <td>${load.rate_per_mile.toFixed(2)}</td>
                          <td>
                            {load.destination_address_city}, {load.destination_address_state}
                          </td>
                          <td>
                            <div className="load-status-container">
                              <span className="load-status-text">
                                {load.load_status.charAt(0).toUpperCase() +
                                  load.load_status.slice(1)}
                              </span>
                              {getStatusIcons(load.load_status, isDriver, isShipper).map(
                                (icon, idx) => (
                                  <span key={idx} className="icon-container">
                                    {icon}
                                    {hasNotification(load.load_status, isDriver, isShipper) && (
                                      <span className="notification">&#9679;</span>
                                    )}
                                  </span>
                                )
                              )}
                              {!getStatusIcons(load.load_status, isDriver, isShipper).length &&
                                hasNotification(load.load_status, isDriver, isShipper) && (
                                  <span className="notification">&#9679;</span>
                                )}
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          {/* {activeTab === "complete" && (
            <div className="user-profile">
              <div className="table-container-dashboard">
                <table className="table-dashboard">
                  <thead>
                    <tr>
                      <th>Origin</th>
                      <th>Rate</th>
                      <th>Destination</th>
                      <th>
                        Status{" "}
                        <button className="refresh-button" onClick={handleRefresh}>
                          <IoRefresh size={20} />
                        </button>{" "}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {completeLoads.map((load, index) => (
                      <tr
                        key={index}
                        className="pill-row"
                        onClick={() => handleRowClick(load.load_id)}>
                        <td>
                          {load.origin_address_city}, {load.origin_address_state}
                        </td>
                        <td>${load.rate_per_mile.toFixed(2)}</td>
                        <td>
                          {load.destination_address_city}, {load.destination_address_state}
                        </td>
                        <td>{load.load_status}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )} */}
        </>
      ) : (
        <div className="no-account-info">
          <p>No load information found at this time.</p>
        </div>
      )}
    </>
  );
};

export default MyLoads;
