import React from "react";
import "./About.css";
import { useNavigate } from "react-router-dom";

function About() {
  return (
    <div>
      <div style={{height: "600px"}}>
        <h2
          style={{
            paddingBlock: "200px",
            paddingInline: "300px",
            width: "fit",
            textAlign: "center",
          }}>
          Coming Soon
        </h2>
      </div>
    </div>
  );
}

export default About;
