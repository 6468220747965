import React, { useState, useContext, useEffect, useCallback } from "react";
import "./loadDetails.css";
import { useNavigate, Link } from "react-router-dom";
import { UserContext } from "../../context/userContext";
import { useParams } from "react-router-dom";
import {
  getSingleLoad,
  updateRateConfirmationUrl,
  updateBillOfLadingUrl,
  updateLoadStatus,
  updateLoadImageUrl,
  getCurrentShipperLoads,
} from "../../supabase/loads";
import {
  deleteAllUserLoad,
  getUserLoadOfShipper,
  getUserLoadOfDriver,
  getDriverRequests,
  AcceptDriver,
  rejectDriver,
} from "../../supabase/userloads.js";
import { getCurrentUserProfile } from "../../supabase/profile.js";
import { createRequest, deleteAllRequests } from "../../supabase/requests.js";
import { deleteLoad } from "../../supabase/deleteLoad.js";
import { createGroup, getLanesChatId } from "../../supabase/groups.js";
import { sendMessage } from "../../supabase/messages.js";
import account_icon from "../../images/account_icon.png";
import Rating from "react-rating";
import { formatLocationString } from "../../utils/formatLocationString.js";
import { uploadRateConfirmation } from "../../supabase/rateconbucket.js";
import { uploadDeliveryConfirmation } from "../../supabase/proofofdeliverybucket.js";
import { IoRefresh } from "react-icons/io5";
import { getReview } from "../../supabase/review.js";
import Modal from "react-modal";
import { addGroupMember } from "../../supabase/groupMembership.js";
import DriverRequest from "../driverrequest/DriverRequest.js";

const LoadDetails = () => {
  const { userId } = useContext(UserContext);
  const [loadData, setLoadData] = useState(null);
  const [userDetails, setUserDetails] = useState("");
  const [shipperUserLoadRecord, setShipperUserLoadRecord] = useState(null);
  const [shipperDetails, setShipperDetails] = useState(null);
  const { loadId } = useParams();
  const [isOwner, setIsOwner] = useState(false);
  const [isLoadDriver, setIsLoadDriver] = useState(false);
  const navigate = useNavigate();
  const [driverRequests, setDriverRequests] = useState(null);
  const [driverOnLoad, setDriverOnLoad] = useState(null);
  const [driveronloadid, setDriveronloadid] = useState(null);
  const [rateConfirmationFile, setRateConfirmationFile] = useState(null);
  const [billOfLadingFile, setBillOfLadingFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [submittedReview, setSubmittedReview] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [additionalFiles, setAdditionalFiles] = useState([]);
  const maxAdditionalFiles = 5;
  const [pinInput, setPinInput] = useState("");
  const [pinError, setPinError] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAlreadySubmittedRequest, setIsAlreadySubmittedRequest] = useState(false);
  const [selectedDriverId, setSelectedDriverId] = useState(null);
  const [driverDetailsModal, setDriverDetailsModal] = useState(false);
  const [shipperNumCompletedLoads, setShipperNumCompletedLoads] = useState(null);

  // State for showing/hiding information
  const [isDriverDetailsShowing, setIsDriverDetailsShowing] = useState(false);
  const [isDriverDocsShowing, setIsDriverDocsShowing] = useState(false);
  const [isOtherSpecsShowing, setIsOtherSpecsShowing] = useState(false);

  const fetchLoadData = useCallback(async () => {
    try {
      const loadData = await getSingleLoad(loadId);
      setLoadData(loadData[0]);

      const user = await getCurrentUserProfile(userId);
      setUserDetails(user[0]);

      const userloaddetails = await getUserLoadOfShipper(loadId);
      setShipperUserLoadRecord(userloaddetails[0]);
      const shipperProfile = await getCurrentUserProfile(userloaddetails[0].user_id);
      setShipperDetails(shipperProfile[0]);
      if (userloaddetails[0].user_id === userId) {
        setIsOwner(true);
      }
      const loads = await getCurrentShipperLoads(userloaddetails[0].user_id);
      const completedLoads = loads.filter((load) => {
        return load.load_status === "complete";
      });
      setShipperNumCompletedLoads(completedLoads.length);

      const requests = await getDriverRequests(loadId);
      setDriverRequests(requests);
      const driversRequested = requests.profiles || [];
      const hasUserRequested = driversRequested.some((driver) => driver.id === userId);
      setIsAlreadySubmittedRequest(hasUserRequested);

      const userloaddetails_driver = await getUserLoadOfDriver(loadId);
      if (userloaddetails_driver[0]) {
        setDriveronloadid(userloaddetails_driver[0].user_id);
        const driver = await getCurrentUserProfile(userloaddetails_driver[0].user_id);
        setDriverOnLoad(driver[0]);
        if (userloaddetails_driver[0].user_id === userId) {
          setIsLoadDriver(true);
        }
      }

      const review = await getReview(userId, loadId);
      setSubmittedReview(review);
    } catch (error) {
      console.error("Error fetching load data:", error);
    }
  }, [loadId, userId]);

  useEffect(() => {
    fetchLoadData();
  }, [fetchLoadData]);

  const handleRefresh = () => {
    fetchLoadData();
  };

  const handleBackNavigation = () => {
    navigate(-1); // send user back to previous location
  };

  const handleDeleteLoad = async () => {
    const confirmDelete = window.confirm("Are you sure you want to delete this load?");
    if (confirmDelete) {
      await deleteAllUserLoad(loadId);
      await deleteAllRequests(loadId);
      await deleteLoad(loadId);
      navigate("/dashboard"); // go back to owner's dashboard
    }
  };

  const handleCopyLoad = () => {
    navigate(`/new_load/${loadId}`);
  };

  const handleEditLoad = () => {
    navigate(`/edit-load/${loadId}`);
  };

  const getLegibleEquipmentType = (equipmentCode) => {
    const equipment = {
      AUTO: "Auto Carrier",
      "B-TR": "B-Train",
      DUMP: "Dump Trailer",
      F: "Flatbed",
      SD: "Step Deck",
      HOPP: "Hopper Bottom",
      LBO_RGN: "Lowboy or RGN",
      R: "Reefer",
      RGN: "Removable Gooseneck",
      TANK: "Tanker",
      V: "Van",
      OTHER: "Other",
    };
    return equipment[equipmentCode];
  };

  const handleRequest = async () => {
    setIsLoading(true);
    await createRequest(userId, loadId);
    await updateLoadStatus(loadId, "pending");
    const lanesChatId = await getLanesChatId(shipperUserLoadRecord.user_id);
    const dataRequester = await getCurrentUserProfile(userId);
    const notificationContent = `Hooray! Driver ${dataRequester[0].full_name} has requested to drive your load from ${loadData.origin_address_city}, ${loadData.origin_address_state} to ${loadData.destination_address_city}, ${loadData.destination_address_state} on ${loadData.pickup_date}. You can review ${dataRequester[0].full_name}'s profile under the load details.`;
    sendMessage(notificationContent, lanesChatId, "2e75306f-614e-4f02-8fbe-3759a58d2367");
    const dataShipper = await getCurrentUserProfile(shipperUserLoadRecord.user_id);
    const new_group = await createGroup(
      `${dataRequester[0].full_name} & ${dataShipper[0].full_name} (${loadData.destination_address_state})`,
      userId
    );
    const group_id = new_group[0].group_id;
    addGroupMember(group_id, userId);
    addGroupMember(group_id, shipperUserLoadRecord.user_id);
    const content = `Hooray! Driver ${dataRequester[0].full_name} has requested to drive your load from ${loadData.origin_address_city}, ${loadData.origin_address_state} to ${loadData.destination_address_city}, ${loadData.destination_address_state} on ${loadData.pickup_date}. ${dataShipper[0].full_name}, do you have any questions for ${dataRequester[0].full_name}?`;
    sendMessage(content, group_id, "2e75306f-614e-4f02-8fbe-3759a58d2367");
    setIsLoading(false);
    navigate("/dashboard"); // send driver back to dashboard, to see newly requested load
  };

  const handleAcceptDriver = async (driver_id) => {
    await AcceptDriver(loadId, driver_id);
    const lanesChatId = await getLanesChatId(driver_id);
    const dataShipper = await getCurrentUserProfile(userId);
    const notificationContent = `Hooray! Shipper ${dataShipper[0].full_name} has approved you to drive for the load from ${loadData.origin_address_city}, ${loadData.origin_address_state} to ${loadData.destination_address_city}, ${loadData.destination_address_state} on ${loadData.pickup_date}.`;
    sendMessage(notificationContent, lanesChatId, "2e75306f-614e-4f02-8fbe-3759a58d2367");
    navigate("/dashboard"); // send owner/shipper back to dashboard
  };

  const handleRejectDriver = async (driver_id) => {
    await rejectDriver(loadId, driver_id);
    const lanesChatId = await getLanesChatId(driver_id);
    const notificationContent = `You have been denied to drive ${loadData.origin_address_city}, ${loadData.origin_address_state} to ${loadData.destination_address_city}, ${loadData.destination_address_state} on ${loadData.pickup_date}. Please send in another request for the load if you believe this is a mistake.`;
    sendMessage(notificationContent, lanesChatId, "2e75306f-614e-4f02-8fbe-3759a58d2367");
    refreshDriverRequests();
  };

  const refreshDriverRequests = async () => {
    const newDriverRequestsData = await getDriverRequests();
    setDriverRequests(newDriverRequestsData);
  };

  const handleRateConfirmationFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setRateConfirmationFile(file);
    }
  };

  const handleBillOfLadingFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setBillOfLadingFile(file);
    }
  };

  const handleDeleteRateConfirmationFile = () => {
    setRateConfirmationFile(null);
  };

  const handleDeleteBillOfLadingFile = () => {
    setBillOfLadingFile(null);
  };

  const handleDeleteFile = () => {
    setSelectedFile(null);
  };

  const handleDeleteAdditionalFile = (index) => {
    const newFiles = [...additionalFiles];
    newFiles.splice(index, 1);
    setAdditionalFiles(newFiles);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleAdditionalFileChange = (event) => {
    if (additionalFiles.length < maxAdditionalFiles) {
      let file = event.target.files[0];
      if (file) {
        setAdditionalFiles([...additionalFiles, file]);
      } else {
        alert("A maximum of 5 files can be uploaded");
      }
    }
  };

  const uploadFiles = async () => {
    setIsLoading(true);
    try {
      await handleUploadRateConfirmation(rateConfirmationFile);
      await handleUploadBillOfLading(billOfLadingFile);
    } catch (error) {
      console.error("Error uploading files:", error);
    } finally {
      setIsLoading(false);
      navigate("/dashboard"); // load owner back to dashboard
    }
  };

  const handleUploadRateConfirmation = async (rateConfirmationFile) => {
    try {
      if (rateConfirmationFile) {
        const response = await uploadRateConfirmation(rateConfirmationFile, loadId, false);
        if (response) {
          await updateLoadStatus(loadId, "rate confirmation sent");
          await updateRateConfirmationUrl(loadId, response, false);
          const lanesChatId = await getLanesChatId(driveronloadid);
          const dataShipper = await getCurrentUserProfile(userId);
          const notificationContent = `Hooray! Shipper ${dataShipper[0].full_name} has sent you the rate confirmation for the load from ${loadData.origin_address_city}, ${loadData.origin_address_state} to ${loadData.destination_address_city}, ${loadData.destination_address_state} on ${loadData.pickup_date}.`;
          sendMessage(notificationContent, lanesChatId, "2e75306f-614e-4f02-8fbe-3759a58d2367");
        }
      }
    } catch (error) {
      console.error("Error uploading rate confirmation:", error);
    }
  };

  const handleUploadBillOfLading = async (billOfLadingFile) => {
    try {
      if (billOfLadingFile) {
        const response = await uploadRateConfirmation(billOfLadingFile, loadData.load_id, false);
        if (response) {
          await updateBillOfLadingUrl(loadData.load_id, response, false);
        }
      }
    } catch (error) {
      console.error("Error uploading bill of lading:", error);
    }
  };

  const handleUploadSignedRateConfirmation = async () => {
    setIsLoading(true);
    try {
      if (selectedFile) {
        const response = await uploadRateConfirmation(selectedFile, loadId, true);
        if (response) {
          await updateLoadStatus(loadId, "pickup");
          await updateRateConfirmationUrl(loadId, response, false);
          const lanesChatId = await getLanesChatId(shipperUserLoadRecord.user_id);
          const dataDriver = await getCurrentUserProfile(driveronloadid);
          const notificationContent = `Driver ${dataDriver[0].full_name} has signed the rate confirmation for the load from ${loadData.origin_address_city}, ${loadData.origin_address_state} to ${loadData.destination_address_city}, ${loadData.destination_address_state} on ${loadData.pickup_date}. Review under the my loads page.`;
          sendMessage(notificationContent, lanesChatId, "2e75306f-614e-4f02-8fbe-3759a58d2367");
        }
      }
    } catch (error) {
      console.error("Error uploading rate confirmation:", error);
    }
    setIsLoading(false);
    navigate("/dashboard"); // send driver back to dashboard
  };

  const handleUploadDeliveryConfirmation = async () => {
    setIsLoading(true);
    try {
      let proofOfDeliveryUrl = null;
      let additionalFilesUrls = [];

      if (selectedFile) {
        proofOfDeliveryUrl = await uploadDeliveryConfirmation(selectedFile, loadId, "POD");
      }

      if (additionalFiles.length > 0) {
        additionalFilesUrls = await Promise.all(
          additionalFiles.map(async (file) => {
            const url = await uploadDeliveryConfirmation(file, loadId, "additional");
            return url;
          })
        );
      }

      if (proofOfDeliveryUrl) {
        await updateLoadImageUrl(loadId, proofOfDeliveryUrl, additionalFilesUrls);
        await updateLoadStatus(loadId, "complete");

        //Chat notification
        const lanesChatId = await getLanesChatId(shipperUserLoadRecord.user_id);
        const dataDriver = await getCurrentUserProfile(driveronloadid);
        const notificationContent = `Hooray! Driver ${dataDriver[0].full_name} has dropped off the load from ${loadData.origin_address_city}, ${loadData.origin_address_state} to ${loadData.destination_address_city}, ${loadData.destination_address_state} on ${loadData.pickup_date}. You can see the drop off confirmation under my loads.`;
        sendMessage(notificationContent, lanesChatId, "2e75306f-614e-4f02-8fbe-3759a58d2367");
      } else {
        console.error("Error: Proof of Delivery URL not generated.");
      }
    } catch (error) {
      console.error("Error uploading delivery confirmation:", error);
    }
    setIsLoading(false);
    navigate("/dashboard"); // driver back to dashboard
  };

  const handleDriverPickup = async () => {
    setIsModalVisible(true);
  };

  const handlePinSubmit = async () => {
    setIsLoading(true);
    try {
      if (pinInput === loadData.verification_pin) {
        setIsModalVisible(false);
        setPinError("");
        await updateLoadStatus(loadId, "in transit");
        const lanesChatId = await getLanesChatId(shipperUserLoadRecord.user_id);
        const dataDriver = await getCurrentUserProfile(driveronloadid);
        const notificationContent = `Hooray! Driver ${dataDriver[0].full_name} has picked up the load from ${loadData.origin_address_city}, ${loadData.origin_address_state} to ${loadData.destination_address_city}, ${loadData.destination_address_state} on ${loadData.pickup_date}.`;
        sendMessage(notificationContent, lanesChatId, "2e75306f-614e-4f02-8fbe-3759a58d2367");
        navigate("/dashboard"); // send driver back to dashboard
      } else {
        setPinError("Incorrect pin. Please try again. \nContact the shipper for the correct pin.");
      }
    } catch (error) {
      console.error("Error confirming pickup:", error);
      setPinError("An error occurred. Please try again.");
    }
    setIsLoading(false);
  };

  const handleNavigateToReview = () => {
    const recipientId = isLoadDriver ? shipperUserLoadRecord.user_id : driveronloadid;
    navigate(`/review/${recipientId}/${loadData.load_id}/${isLoadDriver}`);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setPinError("");
  };

  const handleOpenDriverRequestModal = (driverId) => {
    setSelectedDriverId(driverId);
    setDriverDetailsModal(true);
  };

  const handleCloseDriverDetailsModal = () => {
    setDriverDetailsModal(false);
  };

  return (
    <>
      <div>
        {/* Back Button */}
        <div className="load-details-back-button-container">
          <button className="back-button" onClick={handleBackNavigation}>
            &lt;
            {/* <span className="back_button_text">Dashboard</span> */}
          </button>
        </div>
      </div>
      {loadData ? (
        <div className="load_details_page">
          {/*Load Information*/}
          <div className="load_details_header ">
            <div className="load_details_header_left">
              <div className="load_details_title">
                <h1>
                  {loadData.origin_address_city +
                    ", " +
                    loadData.origin_address_state +
                    " → " +
                    loadData.destination_address_city +
                    ", " +
                    loadData.destination_address_state}
                </h1>
                <h6 className="load_details_posted_date">Posted: {loadData.posted}</h6>
              </div>
              {/* Flex container for status and refresh button */}
              <div className="status-refresh-container">
                <h4>
                  Status:
                  <span title={loadData.load_status} className="status-value">
                    {loadData.load_status.charAt(0).toUpperCase() + loadData.load_status.slice(1)}
                  </span>
                </h4>
                <button className="refresh-button" onClick={handleRefresh}>
                  <IoRefresh size={20} />
                </button>
              </div>
            </div>

            <div className="button_container">
              {/* load mutation buttons */}
              {isOwner && (
                <>
                  <button className="btn-load-details-copy" onClick={handleCopyLoad}>
                    Copy
                  </button>
                  <button className="btn-load-details-edit" onClick={handleEditLoad}>
                    Edit
                  </button>
                  <button className=" btn-load-details-delete" onClick={handleDeleteLoad}>
                    Delete
                  </button>
                </>
              )}
            </div>
          </div>

          <div className="load_details_flex_container">
            <div className="load_details_container_col_left">
              <div className="load_details_load_item">
                <div>
                  <h1 className="load_details_pane_header">Load Information</h1>
                  <div>
                    <table className="load_details_table">
                      <tr>
                        <th>Origin:</th>
                        <td>
                          {formatLocationString(
                            loadData.origin_address_street,
                            loadData.origin_address_city,
                            loadData.origin_address_state,
                            loadData.origin_address_zip
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>Destination:</th>
                        <td>
                          {formatLocationString(
                            loadData.destination_address_street,
                            loadData.destination_address_city,
                            loadData.destination_address_state,
                            loadData.destination_address_zip
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>Description:</th>
                        <td>{loadData.cargo_description ? loadData.cargo_description : "N/A"}</td>
                      </tr>
                      <tr>
                        <th>Category:</th>
                        <td> {loadData.category ? loadData.category : "N/A"}</td>
                      </tr>
                    </table>
                    <table className="load_details_table">
                      <tr>
                        <th>Total Miles:</th>
                        <td>
                          {loadData.google_maps_calculated_miles
                            ? loadData.google_maps_calculated_miles
                            : "N/A"}
                        </td>
                        <th>Total Rate:</th>
                        <td> ${loadData.total_rate ? loadData.total_rate : "N/A"}</td>
                      </tr>
                      <tr>
                        <th>Rate Per Mile:</th>
                        <td>
                          $
                          {Number(loadData.rate_per_mile).toFixed(2)
                            ? Number(loadData.rate_per_mile).toFixed(2)
                            : "N/A"}
                        </td>
                        <th>Pickup Time:</th>
                        <td>
                          {loadData.pickup_time
                            ? new Date("1995-12-17T" + loadData.pickup_time).toLocaleTimeString(
                                "en-US",
                                {
                                  timeStyle: "short",
                                  hour12: true,
                                }
                              )
                            : "N/A"}
                        </td>
                      </tr>

                      <tr>
                        <th>
                          {/* Desired  */}
                          Pickup Date:
                        </th>
                        <td>
                          {new Date(loadData.pickup_date)
                            .toUTCString()
                            .split(":")[0]
                            .split(",")[1]
                            .slice(1, -3)}
                        </td>

                        <th>Delivery Date:</th>
                        <td>
                          {new Date(loadData.delivery_date)
                            .toUTCString()
                            .split(":")[0]
                            .split(",")[1]
                            .slice(1, -3)}
                        </td>
                      </tr>

                      <tr>
                        {loadData.load_status !== "new" && (
                          <>
                            <th>Rate Confirmation:</th>
                            <td>
                              {loadData.rate_confirmation_url ? (
                                <a
                                  href={loadData.rate_confirmation_url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="document-link">
                                  View
                                  {/* Rate Confirmation */}
                                </a>
                              ) : (
                                "N/A"
                              )}
                            </td>
                          </>
                        )}

                        {/*Show the bill of lading for the owner of the load and the driver of the load(only if they have entered the pin to pick up the load) */}
                        {(isOwner ||
                          (isLoadDriver &&
                            ![
                              "new",
                              "pending",
                              "rate confirmation",
                              "rate confirmation sent",
                              "pickup",
                            ].includes(loadData.load_status))) && (
                          <>
                            <th>Bill of Lading:</th>
                            <td>
                              {loadData.bill_of_lading_url ? (
                                <a
                                  href={loadData.bill_of_lading_url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="document-link">
                                  View
                                  {/* Bill of Lading */}
                                </a>
                              ) : (
                                "N/A"
                              )}
                            </td>
                          </>
                        )}
                      </tr>

                      <tr>
                        {/* Hide the delivery confirmation on new loads */}
                        {loadData.load_status !== "new" && (
                          <>
                            <th>Proof of Delivery:</th>
                            <td>
                              {loadData.dropoff_image_url ? (
                                <a
                                  href={loadData.dropoff_image_url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="document-link">
                                  View
                                  {/* Proof of Delivery */}
                                </a>
                              ) : (
                                "N/A"
                              )}
                            </td>
                          </>
                        )}
                        {/* Show the verification pin if the user is the owner (shipper) */}
                        {isOwner && (
                          <>
                            <th>Verification Pin:</th>
                            <td> {loadData.verification_pin}</td>
                          </>
                        )}
                      </tr>

                      <tr>
                        {/* Hide additional delivery files on new loads */}
                        {loadData.load_status !== "new" && (
                          <>
                            <th>Additional Delivery Photos/Files:</th>
                            <td>
                              {loadData.additional_files && loadData.additional_files.length > 0 ? (
                                <ul className="additional-files-list">
                                  {JSON.parse(loadData.additional_files).map((file, index) => (
                                    <li key={index}>
                                      <a
                                        href={file}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="document-link">
                                        View #{index + 1}
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              ) : (
                                "N/A"
                              )}
                            </td>
                          </>
                        )}
                      </tr>
                    </table>
                  </div>
                </div>
              </div>

              <div className="load_details_requirements_item">
                <div>
                  <h1 className="load_details_pane_header">Specifications</h1>
                  <table className="load_details_table">
                    <tr>
                      <th>Weight:</th>
                      <td> {loadData.weight_lb ? loadData.weight_lb + " lb" : "N/A"}</td>
                    </tr>
                    <tr>
                      <th>Length:</th>
                      <td> {loadData.length_ft ? loadData.length_ft + " ft" : "N/A"}</td>
                    </tr>
                    <tr>
                      <th>Width:</th>
                      <td> {loadData.width_ft ? loadData.width_ft + " ft" : "N/A"}</td>
                    </tr>
                    <tr>
                      <th>Height:</th>
                      <td> {loadData.height_ft ? loadData.height_ft + " ft" : "N/A"}</td>
                    </tr>
                  </table>
                  <header className="load_details_section_header">
                    <h2>Other Specifications</h2>
                    <button
                      className="load_details_accordian_button"
                      onClick={() => setIsOtherSpecsShowing(() => !isOtherSpecsShowing)}>
                      {isOtherSpecsShowing ? "▲" : "▼"}
                    </button>
                  </header>
                  {isOtherSpecsShowing && (
                    <>
                      <table className="load_details_table">
                        <tr>
                          <th>Piece Count:</th>
                          <td> {loadData.piece_count ? loadData.piece_count : "N/A"}</td>
                        </tr>
                        <tr>
                          <th>Pallet Count:</th>
                          <td> {loadData.pallet_count ? loadData.pallet_count : "N/A"}</td>
                        </tr>
                        <tr>
                          <th>Equipment Type:</th>
                          <td>
                            {loadData.equipment_type
                              ? getLegibleEquipmentType(loadData.equipment_type) ||
                                loadData.equipment_type
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th>Hazmat Licence:</th>
                          <td>{loadData.required_hazmat_license ? "Required" : "N/A"}</td>
                        </tr>
                        <tr>
                          <th>Doubles Endorsement:</th>
                          <td>{loadData.required_doubles_endorsement ? "Required" : "N/A"}</td>
                        </tr>
                        <tr>
                          <th>Tanker Endorsement:</th>
                          <td>{loadData.required_tanker_endorsement ? "Required" : "N/A"}</td>
                        </tr>
                        <tr>
                          <th>TWIC:</th>
                          <td> {loadData.required_twic ? "Required" : "N/A"}</td>
                        </tr>
                        <tr>
                          <th>TSA Certified:</th>
                          <td> {loadData.required_tsa_certified ? "Required" : "N/A"}</td>
                        </tr>
                        <tr>
                          <th>Special Instructions:</th>
                          <td>
                            {loadData.special_instructions ? loadData.special_instructions : "N/A"}
                          </td>
                        </tr>
                      </table>{" "}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="load_details_container_col_right">
              <div className="load_details_interaction_item">
                {/* Ability for drivers to request to drive the load */}
                <div className="driver-request-container">
                  {loadData &&
                    userDetails &&
                    (loadData.load_status === "new" || loadData.load_status === "pending") &&
                    userDetails.user_type === "driver" && (
                      <>
                        {!isAlreadySubmittedRequest ? (
                          <div className="driver-request-section">
                            <h2>Request Load</h2>
                            <button
                              className="btn-request"
                              onClick={handleRequest}
                              disabled={isLoading}>
                              {isLoading ? "Requesting..." : "Confirm"}
                            </button>
                          </div>
                        ) : (
                          <div className="driver-request-section">
                            <h2>Load Requested</h2>
                            <p>Request is being processed</p>
                          </div>
                        )}
                      </>
                    )}
                </div>
                {/*Rate Confirmation Component*/}
                <div className="rate-confirmation-container">
                  {/* Ability to upload rate confirmation if in that step of the process */}
                  {loadData.load_status === "rate confirmation" && isOwner && (
                    <div className="rate-confirmation-section">
                      <h2>Send Rate Confirmation</h2>

                      <div className="file-upload-section">
                        {rateConfirmationFile ? (
                          <div className="selected-file">
                            <p>Selected File: {rateConfirmationFile.name}</p>
                            <button
                              className="btn-delete"
                              onClick={handleDeleteRateConfirmationFile}>
                              &times;
                            </button>
                          </div>
                        ) : (
                          <input
                            type="file"
                            accept="application/pdf"
                            onChange={handleRateConfirmationFileChange}
                          />
                        )}
                      </div>

                      <div className="file-upload-section">
                        <h3>Bill of Lading</h3>
                        {billOfLadingFile ? (
                          <div className="selected-file">
                            <p>Selected File: {billOfLadingFile.name}</p>
                            <button className="btn-delete" onClick={handleDeleteBillOfLadingFile}>
                              &times;
                            </button>
                          </div>
                        ) : (
                          <input
                            type="file"
                            accept="application/pdf"
                            onChange={handleBillOfLadingFileChange}
                          />
                        )}
                      </div>

                      <button
                        className="btn-upload"
                        onClick={uploadFiles}
                        disabled={!rateConfirmationFile || !billOfLadingFile || isLoading}>
                        {isLoading ? "Uploading..." : "Upload"}
                      </button>
                    </div>
                  )}

                  {/* Awaiting Rate Conirmation Message */}
                  {loadData.load_status === "rate confirmation" && isLoadDriver && (
                    <div className="rate-confirmation-section">
                      <h2>Rate Confirmation Pending</h2>
                      <p>Shipper has not yet sent the rate confirmation.</p>
                    </div>
                  )}

                  {/* Ability for the driver to confirm the rate if the rate confirmation is sent */}
                  {loadData.load_status === "rate confirmation sent" && isLoadDriver && (
                    <div className="rate-confirmation-section">
                      <h3>Sign and Return Rate Confirmation</h3>
                      <a
                        href={loadData.rate_confirmation_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="document-link">
                        View Rate Confirmation
                      </a>
                      <div className="file-upload-section">
                        {selectedFile ? (
                          <div className="selected-file">
                            <p>Selected File: {selectedFile.name}</p>
                            <button className="btn-delete" onClick={handleDeleteFile}>
                              &times;
                            </button>
                          </div>
                        ) : (
                          <input type="file" accept="application/pdf" onChange={handleFileChange} />
                        )}
                      </div>
                      <button
                        className="btn-upload"
                        onClick={handleUploadSignedRateConfirmation}
                        disabled={!selectedFile || isLoading}>
                        {isLoading ? "Uploading..." : "Upload"}
                      </button>
                    </div>
                  )}

                  {/* Awaiting Signed Rate Confirmation Message for Shipper */}
                  {loadData.load_status === "rate confirmation sent" && isOwner && (
                    <div className="rate-confirmation-section">
                      <h2>Rate Confirmation Sent</h2>
                      <p>Driver has not yet signed the rate confirmation.</p>
                    </div>
                  )}

                  {/* Pickup Component */}
                  <div className="pickup-confirmation-container">
                    {/* Ability to confirm pickup if in that step of the process */}
                    {loadData.load_status === "pickup" && isLoadDriver && (
                      <div className="driver-pickup-confirmation-section">
                        <h2 className="driver-pickup-confirmation-title">Pickup Confirmation</h2>
                        <button className="driver-btn-confirm-pickup" onClick={handleDriverPickup}>
                          Confirm Pickup
                        </button>
                      </div>
                    )}

                    {/* Pending pickup message for the shipper */}
                    {loadData.load_status === "pickup" && isOwner && (
                      <div className="shipper-pickup-confirmation-section">
                        <h2 className="shipper-pickup-confirmation-title">Pickup Confirmation</h2>
                        <p className="shipper-pickup-confirmation-message">
                          Driver has not yet confirmed pickup.
                        </p>
                      </div>
                    )}

                    {/* Modal for driver to enter verification pin */}
                    <Modal
                      isOpen={isModalVisible}
                      onRequestClose={closeModal}
                      className="modal-content"
                      overlayClassName="modal-overlay"
                      contentLabel="Enter Verification Pin">
                      <h2>Enter Verification Pin</h2>
                      <input
                        type="text"
                        value={pinInput}
                        onChange={(e) => setPinInput(e.target.value)}
                        maxLength={4}
                        autoFocus
                        style={{ textAlign: "center", width: "80%" }}
                      />
                      {pinError && <p style={{ color: "red" }}>{pinError}</p>}
                      <button className="btn-submit" onClick={handlePinSubmit} disabled={isLoading}>
                        {isLoading ? "Verifying..." : "Submit"}
                      </button>
                      <button className="btn-cancel" onClick={closeModal}>
                        Cancel
                      </button>
                    </Modal>
                  </div>

                  {/* Delivery Component */}
                  <div className="delivery-confirmation-container">
                    {/* Ability to confirm delivery if in that step of the process */}
                    {loadData.load_status === "in transit" && isLoadDriver && (
                      <div className="driver-delivery-confirmation-section">
                        <h2 className="driver-delivery-confirmation-title">
                          Upload Proof of Delivery
                        </h2>
                        <div className="file-upload-section">
                          {selectedFile ? (
                            <div className="selected-file">
                              <p>Selected File: {selectedFile.name}</p>
                              <button className="btn-delete" onClick={handleDeleteFile}>
                                &times;
                              </button>
                            </div>
                          ) : (
                            <input
                              type="file"
                              accept="image/*, application/pdf"
                              onChange={handleFileChange}
                            />
                          )}
                        </div>

                        <h2 className="driver-delivery-confirmation-title">
                          Additional Photos/files
                        </h2>
                        <div className="file-upload-section">
                          {additionalFiles?.length < maxAdditionalFiles ? (
                            <input
                              type="file"
                              accept="image/*, application/pdf"
                              onChange={handleAdditionalFileChange}
                            />
                          ) : (
                            <p>Max files uploaded</p>
                          )}
                          <ul>
                            {additionalFiles?.map((file, index) => (
                              <li className="additional-files-li" key={index}>
                                <span>{file.name}</span>
                                <button
                                  className="btn-delete"
                                  onClick={() => handleDeleteAdditionalFile(index)}>
                                  &times;
                                </button>
                              </li>
                            ))}
                          </ul>
                        </div>

                        <button
                          className="btn-upload"
                          onClick={handleUploadDeliveryConfirmation}
                          disabled={!selectedFile || isLoading}>
                          {isLoading ? "Uploading..." : "Upload"}
                        </button>
                      </div>
                    )}

                    {/* Pending delivery message for the shipper */}
                    {loadData.load_status === "in transit" && isOwner && (
                      <div className="shipper-delivery-confirmation-section">
                        <h2 className="shipper-delivery-confirmation-title">
                          Waiting for Delivery Confirmation
                        </h2>
                        <p className="shipper-delivery-confirmation-message">
                          Driver has not yet confirmed delivery.
                        </p>
                      </div>
                    )}

                    {/* Review Component */}
                    {loadData.load_status === "complete" && (
                      <>
                        {!submittedReview && (
                          <div className="review-section">
                            <h2>Leave a Review</h2>
                            <button
                              className="review-button"
                              onClick={handleNavigateToReview}
                              disabled={isLoading}>
                              {isLoading ? "Loading..." : "Review"}
                            </button>
                          </div>
                        )}
                      </>
                    )}

                    {/* Review Submitted Message */}
                    {loadData.load_status === "complete" && submittedReview && (
                      <div className="review-section">
                        <h2>Review Submitted</h2>
                        <p>Thank you for your review!</p>
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <div>
                    {isOwner && (
                      <>
                        {loadData.load_status === "new" || loadData.load_status === "pending" ? (
                          <>
                            <h1 className="load_details_pane_header">Driver Requests</h1>
                            {driverRequests &&
                            driverRequests.profiles &&
                            driverRequests.profiles.length > 0 ? (
                              <>
                                {/* Table to display driverRequests.profile array */}
                                <table style={{ width: "100%" }}>
                                  <thead>
                                    <tr>
                                      <th style={{ textAlign: "left", width: "25%" }}>
                                        Driver Name
                                      </th>
                                      <th style={{ textAlign: "left", width: "25%" }}>Phone</th>
                                      <th style={{ textAlign: "center", width: "25%" }}>Action</th>
                                      <th style={{ textAlign: "left", width: "25%" }}>
                                        Driver Information
                                      </th>
                                      {/* Add more headers if needed */}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {driverRequests.profiles.map((profileItem, index) => (
                                      <tr key={index}>
                                        <td>{profileItem.full_name}</td>
                                        <td>{profileItem.phone_number}</td>
                                        <td style={{ textAlign: "center" }}>
                                          <div className="button-container-requests">
                                            <button
                                              className="btn-driver-accept"
                                              onClick={() => handleAcceptDriver(profileItem.id)}>
                                              Accept
                                            </button>
                                            <button
                                              className="btn-driver-reject"
                                              onClick={() => handleRejectDriver(profileItem.id)}>
                                              Reject
                                            </button>
                                          </div>
                                        </td>
                                        <td>
                                          <button
                                            onClick={() =>
                                              handleOpenDriverRequestModal(profileItem.id)
                                            }
                                            className="btn-load-view_info">
                                            View Info
                                          </button>
                                          <Modal
                                            isOpen={driverDetailsModal}
                                            onRequestClose={handleCloseDriverDetailsModal}
                                            className="driver-modal-content"
                                            overlayClassName="modal-overlay"
                                            contentLabel="Driver Request">
                                            <div className="modal-header">
                                              <h2>Driver Request</h2>
                                              <button
                                                className="btn-close"
                                                onClick={handleCloseDriverDetailsModal}>
                                                &times;
                                              </button>
                                            </div>
                                            <div>
                                              <DriverRequest driverId={selectedDriverId} />
                                            </div>
                                          </Modal>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                                {/* End of table */}
                              </>
                            ) : (
                              <p style={{ textAlign: "center" }}>No requests at this time.</p>
                            )}
                          </>
                        ) : driverOnLoad ? (
                          <div>
                            <h1 className="load_details_pane_header">Driver</h1>
                            <div className="user-profile-load-details">
                              <img
                                src={
                                  driverOnLoad.avatar_url ? driverOnLoad.avatar_url : account_icon
                                }
                                alt={driverOnLoad.avatar_url ? "User Image" : "Account Icon"}
                              />
                              <Rating
                                initialRating={driverOnLoad.rating}
                                emptySymbol={<span className="icon-star-empty">&#9734;</span>}
                                fullSymbol={<span className="icon-star-full">&#9733;</span>}
                                readonly
                                className="custom-rating-load-details"
                              />
                            </div>
                            <table className="load_details_table">
                              <tr>
                                <th>Name:</th>
                                <td> {driverOnLoad.full_name}</td>
                              </tr>
                              <tr>
                                <th>Phone:</th>
                                <td> {driverOnLoad.phone_number}</td>
                              </tr>
                            </table>
                            <header className="load_details_section_header">
                              <h2>Documents</h2>
                              <button
                                className="load_details_accordian_button"
                                onClick={() => setIsDriverDocsShowing(() => !isDriverDocsShowing)}>
                                {isDriverDocsShowing ? "▲" : "▼"}
                              </button>
                            </header>
                            {isDriverDocsShowing && (
                              <table className="load_details_table">
                                <tr>
                                  <th>Insurance Information:</th>
                                  <td>
                                    {driverOnLoad.insurance_url ? (
                                      <a
                                        href={driverOnLoad.insurance_url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="document-link">
                                        View Insurance
                                      </a>
                                    ) : (
                                      "N/a"
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <th>MC Certificate:</th>
                                  <td>
                                    {driverOnLoad.mc_cert_url ? (
                                      <a
                                        href={driverOnLoad.mc_cert_url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="document-link">
                                        View MC Certificate
                                      </a>
                                    ) : (
                                      "N/a"
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <th>W4 Form:</th>
                                  <td>
                                    {driverOnLoad.w4_url ? (
                                      <a
                                        href={driverOnLoad.w4_url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="document-link">
                                        View W4 Form
                                      </a>
                                    ) : (
                                      "N/a"
                                    )}
                                  </td>
                                </tr>
                              </table>
                            )}
                            <header className="load_details_section_header">
                              <h2>Other Details</h2>
                              <button
                                className="load_details_accordian_button"
                                onClick={() =>
                                  setIsDriverDetailsShowing(() => !isDriverDetailsShowing)
                                }>
                                {isDriverDetailsShowing ? "▲" : "▼"}
                              </button>
                            </header>
                            {isDriverDetailsShowing && (
                              <table className="load_details_table">
                                <tr>
                                  <th>Equipment Type:</th>
                                  <td> {driverOnLoad.equipment_type}</td>
                                </tr>
                                <tr>
                                  <th>Hazmat License:</th>
                                  <td>{driverOnLoad.hazmat_license ? "Yes" : "No"}</td>
                                </tr>
                                <tr>
                                  <th>Doubles License:</th>
                                  <td>{driverOnLoad.doubles_endorsement ? "Yes" : "No"}</td>
                                </tr>
                                <tr>
                                  <th>Tanker License:</th>
                                  <td>{driverOnLoad.tanker_endoresement ? "Yes" : "No"}</td>
                                </tr>
                                <tr>
                                  <th>Max Insurance Converage:</th>
                                  <td>{driverOnLoad.max_insurance_coverage}</td>
                                </tr>
                                <tr>
                                  <th>Max Length:</th>
                                  <td> {driverOnLoad.max_length}</td>
                                </tr>
                                <tr>
                                  <th>Max Weight:</th>
                                  <td> {driverOnLoad.max_weight}</td>
                                </tr>
                                <tr>
                                  <th>Twic Card:</th>
                                  <td> {driverOnLoad.twic_card ? "Yes" : "No"}</td>
                                </tr>
                              </table>
                            )}
                          </div>
                        ) : (
                          <div className="no-driver-message">
                            <h2>No driver information could be displayed at this time.</h2>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              {shipperDetails && !isOwner && (
                <div className="load_details_owner_item">
                  <div>
                    <div>
                      <h1 className="load_details_pane_header">Posting Company</h1>
                      <div className="user-profile-load-details">
                        <img
                          src={shipperDetails.avatar_url ? shipperDetails.avatar_url : account_icon}
                          alt={shipperDetails.avatar_url ? "User Image" : "Account Icon"}
                        />
                        <Rating
                          initialRating={shipperDetails.rating}
                          emptySymbol={<span className="icon-star-empty">&#9734;</span>}
                          fullSymbol={<span className="icon-star-full">&#9733;</span>}
                          readonly
                          className="custom-rating-load-details"
                        />
                      </div>

                      <table className="load_details_table">
                        <tr>
                          <th>Name:</th> <td>{shipperDetails.full_name}</td>
                        </tr>
                        <tr>
                          <th>Phone:</th>
                          <td>{shipperDetails.phone_number}</td>
                        </tr>
                        <tr>
                          <th>Completed Loads:</th>
                          <td>{shipperNumCompletedLoads}</td>
                        </tr>
                        <tr>
                          <th>Website:</th>
                          <td>
                            {shipperDetails.website ? (
                              <Link to={shipperDetails.website}>{shipperDetails.website}</Link>
                            ) : (
                              "N/A"
                            )}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="no-account-info">
          <p>No load information found at this time.</p>
        </div>
      )}
    </>
  );
};

export default LoadDetails;
