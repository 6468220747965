// DriverRequest.js
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./DriverRequest.css";
import { getCurrentUserProfile } from "../../supabase/profile";
import Rating from "react-rating";
import account_icon from "../../images/account_icon.png";
import { getCurrentDriverLoads } from "../../supabase/loads";

const DriverRequest = ({ driverId }) => {
  const [driverData, setDriverData] = useState(null);
  const [numLoads, setNumLoads] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (driverId) {
          const profileData = await getCurrentUserProfile(driverId);
          setDriverData(profileData[0]);
          const loads = await getCurrentDriverLoads(driverId);
          if (loads) {
            setNumLoads(loads.length);
          }
        }
      } catch (error) {
        setError("Error fetching data");
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [driverId]);

  if (loading) {
    return (
      <div className="driver-request-modal">
        <p>Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="driver-request-modal">
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="driver-request-modal">
      {driverData ? (
        <div className="driver-details">
          <div className="profile-header">
            <div className="user-profile-load-details">
              <img
                src={
                  driverData.avatar_url ? driverData.avatar_url : account_icon
                }
                alt={driverData.avatar_url ? "User Image" : "Account Icon"}
              />
            </div>
            <Rating
              initialRating={driverData.rating}
              emptySymbol={<span className="icon-star-empty">&#9734;</span>}
              fullSymbol={<span className="icon-star-full">&#9733;</span>}
              readonly
              className="custom-rating-load-details"
            />
          </div>
          <div className="driver-info-columns">
            <div className="column">
              <p>
                <strong>Name:</strong> {driverData.full_name}
              </p>
              <p>
                <strong>Phone:</strong> {driverData.phone_number}
              </p>
              <p>
                <strong>Completed Loads:</strong> {numLoads}
              </p>
              <p>
                <strong>Equipment Type:</strong> {driverData.equipment_type}
              </p>
              <p>
                <strong>Hazmat License:</strong>{" "}
                {driverData.hazmat_license ? "Yes" : "No"}
              </p>
              <p>
                <strong>Doubles License:</strong>{" "}
                {driverData.doubles_endorsement ? "Yes" : "No"}
              </p>
              <p>
                <strong>Tanker License:</strong>{" "}
                {driverData.tanker_endorsement ? "Yes" : "No"}
              </p>
            </div>
            <div className="column">
              <p>
                <strong>Max Insurance Coverage:</strong>{" "}
                {driverData.max_insurance_coverage}
              </p>
              <p>
                <strong>Max Length:</strong> {driverData.max_length}
              </p>
              <p>
                <strong>Max Weight:</strong> {driverData.max_weight}
              </p>
              <p>
                <strong>Twic Card:</strong>{" "}
                {driverData.twic_card ? "Yes" : "No"}
              </p>
              <p>
                <strong>Insurance Information:</strong>{" "}
                {driverData.insurance_url ? (
                  <a
                    href={driverData.insurance_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Insurance
                  </a>
                ) : (
                  "No Upload"
                )}
              </p>
              <p>
                <strong>MC Certificate:</strong>{" "}
                {driverData.mc_cert_url ? (
                  <a
                    href={driverData.mc_cert_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View MC Certificate
                  </a>
                ) : (
                  "No Upload"
                )}
              </p>
              <p>
                <strong>W4 Form:</strong>{" "}
                {driverData.w4_url ? (
                  <a
                    href={driverData.w4_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View W4 Form
                  </a>
                ) : (
                  "No Upload"
                )}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <p>No driver information could be displayed at this time.</p>
      )}
    </div>
  );
};

DriverRequest.propTypes = {
  driverId: PropTypes.string,
};

export default DriverRequest;
