// Author: Ryan Pinkney
// This file will handle the supabase calls to add records to the group_membership table, which adds people
// to different group chats
import { supabase } from './supabase';

// Creates a new groups record
// Pass a groupName and ownerId to be used in the function
const addGroupMember  = async (groupId, userId) => { 

    const { data, error } = await supabase
    .from('group_membership')
    .insert([
        {   
        group_id: groupId,
        user_id: userId,
        created_at: new Date(),
        },
    ])
    .select()

    if (error) {
    console.error('Error adding group:', error);
    return;
    } else {
    return data;
    }
}

const markAsRead = async (groupId, userId) => {

    // Update the corresponding group with the groupId and set 'unread' to true
    const { error: groupError } = await supabase
    .from('group_membership')
    .update({ unread: false })
    .eq('group_id', groupId)
    .eq('user_id', userId)

    if (groupError) {
    console.error('Error updating group unread status:', groupError);
    }
}

// Function to remove person from group
const removeGroupMember = async (groupId, userId) => {
    const { data, error } = await supabase
        .from('group_membership')
        .delete()
        .eq('group_id', groupId)
        .eq('user_id', userId)
        .select();

    if (error) {
        console.error('Error removing group member:', error);
        return;
    } else {
        return data;
    }
}


export { addGroupMember, markAsRead, removeGroupMember }