// Author: Ryan Pinkney
// This file creates a user load record that will notify driver and shippers during negotiation
//

import { supabase } from './supabase';

// Inserts a userloads record when a driver requests to drive for a load
const createUserLoad  = async (userId, loadId, relationshipType) => { 

    const { data, error } = await supabase
    .from('userloads')
    .insert([
        {   
        relationship_type: relationshipType,
        user_id: userId,
        load_id: loadId
        },
    ])
    .select()

    if (error) {
      console.error('Error adding load:', error);
    return;
    } else {
      console.log("Record created")
      return data;
    }
};

const getUserLoadOfShipper = async (load_id) => {
    // Fetch the corresponding load data based on user_id and status 'Pending'
    const { data: userloaddetails, error: userloadserror } = await supabase
    .from('userloads')
    .select('*')
    .eq('relationship_type', 'shipper')
    .eq('load_id', load_id)
    

  if (userloadserror) {
      console.error('Error fetching load data:', userloadserror);
      return;
  } else {
    return userloaddetails;
  }
};

const getUserLoadOfDriver = async (load_id) => {

  // Fetch the corresponding load data based on user_id and status 'Pending'
  const { data: userloaddetails, error: userloadserror } = await supabase
  .from('userloads')
  .select('*')
  .eq('relationship_type', 'driver')
  .eq('load_id', load_id)
  

if (userloadserror) {
    console.error('Error fetching load data:', userloadserror);
    return;
} else {
  return userloaddetails;
}
}

// Returns all the loads the driver has request for the driver view
const getPendingLoadsDriver = async (user_id) => {

    // Fetch the corresponding load data based on user_id and status 'Pending'
    const { data: requestsDetails, error: requestError } = await supabase
        .from('requests')
        .select('load_id')
        .eq('driver_id', user_id)
        .eq('status', 'pending')
       

    if (requestError) {
        console.error('Error fetching requests:', requestError);
        return;
    } 

    // Extract load_ids from loadDetails
    const loadIds = requestsDetails.map(detail => detail.load_id);

    // Fetch loads from the loads table based on load_ids
    const { data: loads, error: loadsError } = await supabase
      .from('loads')
      .select('*')
      .in('load_id', loadIds)
      .eq('load_status', 'pending');

    if (loadsError) {
      console.error('Error fetching loads:', loadsError);
      return;
    } else {

      return loads;
       }
};

// Returns all the loads that have been requested be drivers for the shipper view
const getPendingLoadsShipper = async (user_id) => {
    // Fetch all loads from the 'loads' table where user id is equal to shipper id
    const { data: loadDetails, error: loadError } = await supabase
      .from('userloads')
      .select('load_id')
      .eq('user_id', user_id)
      .eq('relationship_type', 'shipper');
  
    if (loadError) {
      console.error('Error fetching load details:', loadError);
      return;
    } else {
      // Extract load_ids from loadDetails
    
      const loadIds = loadDetails.map(detail => detail.load_id);

      // Fetch loads from the loads table based on load_ids
      const { data: loads, error: loadsError } = await supabase
        .from('loads')
        .select('*')
        .in('load_id', loadIds)
        .eq('load_status', 'pending');

      if (loadsError) {
        console.error('Error fetching loads:', loadsError);
        return;
      } else {

        return loads;
      }
    }
  };
  
  // Returns all the drivers that have requested to drive for a load and returns their information
  const getDriverRequests = async (load_id) => {
    // Fetch userloads data based on load_id and status 'Pending'
    const { data: requestsData, error: requestError } = await supabase
      .from('requests')
      .select()
      .eq('load_id', load_id) // Fetch based on load_id
      .eq('status', 'pending');
  
    if (requestError) {
      console.error('Error fetching userload data:', requestError);
      return { requestsData: [] };
    }

    // Extract user_ids from userloaddata
    const user_ids = requestsData.map(entry => entry.driver_id);

    // Fetch profiles where user_id is in the extracted user_ids
    const { data: profiles, error: profilesError } = await supabase
      .from('profiles')
      .select()
      .in('id', user_ids);
  
    if (profilesError) {
      console.error('Error fetching profiles for the requests:', profilesError);
      return { userloaddata: [], loaddata: [], profiles: [] };
    }
    
    return { requestsData, profiles };
  };

  // Database insert that runs when the shipper accepts the driver for the load
  // First we create a userloads record.
  // Then, we update the status of the load
  // Then, we update the status of the requests
  const AcceptDriver = async (load_id, driver_id) => {
    try {
      // Insert into userloads table
      const { error: userLoadsError } = await supabase
          .from('userloads')
          .insert([
              {
                load_id: load_id,
                user_id: driver_id,
                relationship_type: 'driver',
                // Include any other columns and values you need to insert
              }
          ]);

      if (userLoadsError) {
          console.error('Error inserting into userloads on accept driver handle:', userLoadsError);
          return { userloaddata: [], loaddata: [] };
      }
  
      // Update loads table
      const { data: updatedLoadData, error: loadError } = await supabase
        .from('loads')
        .update({
          load_status: 'rate confirmation',
        })
        .eq('load_id', load_id);
  
      if (loadError) {
        console.error('Error updating driver_id on load for accept driver handle', loadError);
        return {loaddata: [] };
      }

      // Update requests table
      const { error: requesterror } = await supabase
      .from('requests')
      .update({
        status: 'accepted',
      })
      .eq('load_id', load_id)
      .eq('driver_id', driver_id);

    if (requesterror) {
      console.error('Error updating the request for the accept driver', requesterror);
      return {request: [] };
    }
  
      return { loaddata: updatedLoadData };
    } catch (error) {
      console.error('Error in AcceptDriver:', error.message);
      return { loaddata: [] };
    }
  };

// Function that rejects the driver
// There are several steps. First, we have to mark the request records has rejected
// Then, we have to check if there are any other requests for that load
// If there are not, we want to update the load status back to 'new'
const rejectDriver  = async (load_id, driver_id) => { 
    // Update requests table
    const { error: requesterror } = await supabase
    .from('requests')
    .update({
      status: 'rejected',
    })
    .eq('load_id', load_id)
    .eq('driver_id', driver_id);

    if (requesterror) {
    console.error('Error updating request status:', requesterror);
    return;
    }


    // Fetch request data based on load_id and status 'Pending'
    const { data: requestsData, error: errorlist } = await supabase
    .from('requests')
    .select()
    .eq('load_id', load_id) // Fetch based on load_id
    .eq('status', 'pending');

  if (errorlist) {
    console.error('Error finding requests:', errorlist);
    return;
    }

  if (requestsData.length === 0) {
    // Update the status of the corresponding record to 'new'
    const { error } = await supabase
    .from('loads')
    .update({ load_status: 'new'})
    .eq('load_id', load_id);

  if (error) {
    console.error('Error updating load:', error);
    return;
    }
  }
};

// This function will only ever delete one row in particular where the load_id and the user_id match
const deleteUserLoad = async (load_id, user_id) => {
  const { error } = await supabase
    .from('userloads')
    .delete()
    .eq('user_id', user_id)
    .eq('load_id', load_id);

  if (error) {
    console.error('Error deleting user load:', error);
    return;
  } else {
    return;
  }
};

// Function used in the delete load function
// This function may delete multiple records if there is both a driver and a shipper on the load
const deleteAllUserLoad = async (load_id, ) => {
  const { error } = await supabase
    .from('userloads')
    .delete()
    .eq('load_id', load_id);

  if (error) {
    console.error('Error deleting user loads:', error);
    return;
  } else {
    return;
  }
};

  



// Export all the functions
export {createUserLoad, getPendingLoadsDriver, getPendingLoadsShipper, getDriverRequests, AcceptDriver, rejectDriver, getUserLoadOfShipper, getUserLoadOfDriver, deleteUserLoad, deleteAllUserLoad}