import React, { createContext, useContext, useEffect, useState } from "react";
import { supabase } from "../supabase/supabase";

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [userId, setUserId] = useState(null);

  const [session, setSession] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const setData = async () => {
      const {
        data: { session },
        error,
      } = await supabase.auth.getSession();
      if (error) throw error;
      setSession(session);
      setUserId(session?.user.id);
      setLoading(false);
    };

    const { data: listener } = supabase.auth.onAuthStateChange((_event, session) => {
      // console.log(_event);
      setSession(session);
      setUserId(session?.user.id);

      setLoading(false);
    });

    setData();

    return () => {
      listener?.subscription.unsubscribe();
    };
  }, []);

  const signUserOut = async () => {
    const { data, response, error } = await supabase.auth.signOut({
      scope: "global",
    });

    // console.log("data", data);
    // console.log("response", response);
    // console.log("error", error);
    // if error and 403, manually sign out by clearing data (local storage, cookies or such)?
    // localStorage.clear();
  };

  const sessionData = {
    session,
    signOut: () => signUserOut(),
  };

  return (
    <UserContext.Provider value={{ sessionData, userId, setUserId }}>
      {!loading && children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };

export const useAuth = () => {
  return useContext(UserContext);
};
