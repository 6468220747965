// Author: Ryan Pinkney
// Create a new load page

import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/userContext";
import { createLoad } from "../../supabase/createLoad";
import "./CreateLoad.css";
import { getStateCodes } from "../../utils/getStateCodes";
import { useParams } from "react-router-dom";
import { getSingleLoad } from "../../supabase/loads";

const stateAbbreviationList = getStateCodes();

const CreateLoad = () => {
  const params = useParams();
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [rate, setRate] = useState("");
  const [pickupDate, setPickupDate] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("");
  const [weight, setWeight] = useState("");
  const [palletCount, setPalletCount] = useState("");
  const [equipmentType, setEquipmentType] = useState("");
  const [specialInstructions, setSpecialInstructions] = useState("");
  const [pieceCount, setPieceCount] = useState("");
  const [lengthFt, setLengthFt] = useState("");
  const [widthFt, setWidthFt] = useState("");
  const [originStreet, setOriginStreet] = useState("");
  const [originCity, setOriginCity] = useState("");
  const [originState, setOriginState] = useState("");
  const [originZip, setOriginZip] = useState("");
  const [destinationStreet, setDestinationStreet] = useState("");
  const [destinationCity, setDestinationCity] = useState("");
  const [destinationState, setDestinationState] = useState("");
  const [destinationZip, setDestinationZip] = useState("");
  const [hazmatLicenseRequired, setHazmatLicenseRequired] = useState(false);
  const [doublesEndorsementRequired, setDoublesEndorsementRequired] = useState(false);
  const [tankerEndorsementRequired, setTankerEndorsementRequired] = useState(false);
  const [twicRequired, setTwicRequired] = useState(false);
  const [tsaCertifiedRequired, setTsaCertifiedRequired] = useState(false);
  const [heightFt, setHeightFt] = useState("");
  const [pickupTime, setPickupTime] = useState("");

  const [message, setMessage] = useState("");
  const [isErrorMessage, setIsErrorMessage] = useState(false);

  const { userId } = useContext(UserContext);

  const requiredMarker = <span style={{ color: "#DC3545" }}>*</span>;

  useEffect(() => {
    if (params.loadId) {
      getSingleLoad(params.loadId)
        .then((load) => {
          // populate form state with load data, if user copying a load via id in params
          setDescription(load[0].cargo_description);
          setCategory(load[0].category);
          setPickupDate(load[0].pickup_date);
          setPickupTime(load[0].pickup_time);
          setDeliveryDate(load[0].delivery_date);
          setOriginStreet(load[0].origin_address_street);
          setOriginCity(load[0].origin_address_city);
          setOriginState(load[0].origin_address_state);
          setOriginZip(load[0].origin_address_zip);
          setDestinationStreet(load[0].destination_address_street);
          setDestinationCity(load[0].destination_address_city);
          setDestinationState(load[0].destination_address_state);
          setDestinationZip(load[0].destination_address_zip);
          setRate(load[0].total_rate);
          setWeight(load[0].weight_lb);
          setPalletCount(load[0].pallet_count);
          setPieceCount(load[0].piece_count);
          setEquipmentType(load[0].equipment_type);
          setLengthFt(load[0].length_ft);
          setWidthFt(load[0].width_ft);
          setHeightFt(load[0].height_ft);
          setSpecialInstructions(load[0].special_instructions);
          setDoublesEndorsementRequired(load[0].required_doubles_endorsement);
          setHazmatLicenseRequired(load[0].required_hazmat_license);
          setTankerEndorsementRequired(load[0].required_tanker_endorsement);
          setTwicRequired(load[0].required_twic);
          setTsaCertifiedRequired(load[0].required_tsa_certified);
        })
        .catch((error) => {
          console.error("Error copying load data:", error);
        });
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    // validate form
    if (
      // description === "" ||
      // category === "" ||
      rate === "" ||
      // weight === "" ||
      // lengthFt === "" ||
      // widthFt === "" ||
      // equipmentType === "" ||
      pickupDate === "" ||
      deliveryDate === ""
      // originStreet === "" ||
      // originCity === "" ||
      // originState === "" ||
      // originZip === "" ||
      // destinationStreet === "" ||
      // destinationCity === "" ||
      // destinationState === "" ||
      // destinationZip === "" ||
      // palletCount === "" ||
      // pieceCount === ""
    ) {
      setMessage("Please fill all required fields");
      setIsErrorMessage(true);
    } else if (new Date(pickupDate) > new Date(deliveryDate)) {
      setMessage("Desired Pick Up Date must be prior to or equal to Desired Delivery Date");
      setIsErrorMessage(true);
    } else {
      setMessage("");
      setIsErrorMessage(false);

      let objPickupDate = new Date(pickupDate);
      let objDeliveryDate = new Date(deliveryDate);
      // create load
      const load = createLoad(userId, {
        cargoDescription: description,
        cargoCategory: category,
        totalRate: rate,
        weightLb: weight,
        lengthFt: lengthFt,
        widthFt: widthFt,
        heightFt: heightFt,
        equipmentType: equipmentType,
        specialInstructions: specialInstructions,
        pickupDate: objPickupDate,
        deliveryDate: objDeliveryDate,
        pickupTime: pickupTime,
        pickupAddressStreet: originStreet,
        pickupAddressCity: originCity,
        pickupAddressState: originState,
        pickupAddressZip: originZip,
        deliveryAddressStreet: destinationStreet,
        deliveryAddressCity: destinationCity,
        deliveryAddressState: destinationState,
        deliveryAddressZip: destinationZip,
        palletCount: palletCount,
        pieceCount: pieceCount,
        hazmatLicenseRequired: hazmatLicenseRequired,
        doublesEndorsementRequired: doublesEndorsementRequired,
        tankerEndorsementRequired: tankerEndorsementRequired,
        twicRequired: twicRequired,
        tsaCertifiedRequired: tsaCertifiedRequired,
      });

      // cleanup for next load input
      setDescription("");
      setCategory("");
      setRate("");
      setWeight("");
      setLengthFt("");
      setWidthFt("");
      setHeightFt("");
      setEquipmentType("");
      setSpecialInstructions("");
      setPickupDate("");
      setDeliveryDate("");
      setPickupTime("");
      setOriginStreet("");
      setOriginCity("");
      setOriginState("");
      setOriginZip("");
      setDestinationStreet("");
      setDestinationCity("");
      setDestinationState("");
      setDestinationZip("");
      setPalletCount("");
      setPieceCount("");
      setDoublesEndorsementRequired(false);
      setHazmatLicenseRequired(false);
      setTankerEndorsementRequired(false);
      setTwicRequired(false);
      setTsaCertifiedRequired(false);

      // notify user of success
      setMessage("Load created successfully!");
    }
  };

  return (
    <div>
      <form className="form-create-load" onSubmit={handleSubmit}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: "1px",
            height: 700,
          }}>
          {/* Left Column */}
          <div style={{ flex: "0 0 50%", padding: "0 1rem" }}>
            <div>
              <div>
                <label htmlFor="pickupDate">{requiredMarker}Pickup Date</label>
                <input
                  id="pickupDate"
                  type="date"
                  min={new Date().toISOString().split("T")[0]}
                  value={pickupDate}
                  onChange={(e) => setPickupDate(e.target.value)}
                />
              </div>

              <label htmlFor="pickupAddress">Pickup Address</label>
              <input
                id="pickupAddress"
                type="text"
                value={originStreet}
                onChange={(e) => setOriginStreet(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="pickupCity">Pickup City</label>
              <input
                id="pickupCity"
                type="text"
                value={originCity}
                onChange={(e) => setOriginCity(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="pickupStateSelect">Pickup State</label>
              <br></br>
              <select
                value={originState}
                onChange={(e) => setOriginState(e.target.value)}
                id="pickupStateSelect"
                name="pickupStateSelect">
                <option value={""} disabled={true}>
                  Select a State
                </option>
                {stateAbbreviationList.map((state) => {
                  return (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  );
                })}
              </select>
            </div>

            <div>
              <label htmlFor="pickupZip">Pickup Zip</label>
              <input
                id="pickupZip"
                type="text"
                value={originZip}
                onChange={(e) => setOriginZip(e.target.value)}
              />
            </div>

            <div>
              <label htmlFor="pickupTime">Pickup Time</label>
              <input
                id="pickupTime"
                type="time"
                value={pickupTime}
                onChange={(e) => setPickupTime(e.target.value)}
              />
            </div>

            <div>
              <label htmlFor="rate">{requiredMarker}Rate</label>
              <input
                id="rate"
                type="number"
                min={0}
                value={rate}
                onChange={(e) => setRate(e.target.value)}
              />
            </div>

            <div>
              <label htmlFor="category">Category</label>
              <input
                id="category"
                type="text"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="description">Cargo Description</label>
              <input
                id="description"
                type="text"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>

            <div>
              <label htmlFor="lengthFt">Length in Feet</label>
              <input
                id="lengthFt"
                type="number"
                min={0}
                value={lengthFt}
                onChange={(e) => setLengthFt(e.target.value)}
              />
            </div>
          </div>
          {/* Center Column */}
          <div style={{ flex: "0 0 50%", padding: "0 1rem" }}>
            <div>
              <label htmlFor="deliveryDate">{requiredMarker}Delivery Date</label>
              <input
                id="deliveryDate"
                type="date"
                min={new Date().toISOString().split("T")[0]}
                value={deliveryDate}
                onChange={(e) => setDeliveryDate(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="destinationAddress">Destination Address</label>
              <input
                id="destinationAddress"
                type="text"
                value={destinationStreet}
                onChange={(e) => setDestinationStreet(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="destinationCity">Destination City</label>
              <input
                id="destinationCity"
                type="text"
                value={destinationCity}
                onChange={(e) => setDestinationCity(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="destinationStateSelect">Destination State</label>
              <br></br>
              <select
                value={destinationState}
                onChange={(e) => setDestinationState(e.target.value)}
                id="destinationStateSelect"
                name="destinationStateSelect">
                <option value={""} disabled={true}>
                  Select a State
                </option>
                {stateAbbreviationList.map((state) => {
                  return (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  );
                })}
              </select>
            </div>

            <div>
              <label htmlFor="destinationZip">Destination Zip</label>
              <input
                id="destinationZip"
                type="text"
                value={destinationZip}
                onChange={(e) => setDestinationZip(e.target.value)}
              />
            </div>

            <div>
              <label htmlFor="pieceCount">Piece Count</label>
              <input
                id="pieceCount"
                type="number"
                min={0}
                value={pieceCount}
                onChange={(e) => setPieceCount(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="palletCount">Pallet Count</label>
              <input
                id="palletCount"
                type="number"
                min={0}
                value={palletCount}
                onChange={(e) => setPalletCount(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="weight">Weight</label>
              <input
                id="weight"
                type="number"
                min={0}
                value={weight}
                onChange={(e) => setWeight(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="widthFt">Width in Feet</label>
              <input
                id="widthFt"
                type="number"
                min={0}
                value={widthFt}
                onChange={(e) => setWidthFt(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="heightFt">Height in Feet</label>
              <input
                id="heightFt"
                type="number"
                min={0}
                value={heightFt}
                onChange={(e) => setHeightFt(e.target.value)}
              />
            </div>
          </div>

          {/* Right Column */}
          <div style={{ flex: "0 0 50%", padding: "0 1rem" }}>
            <div>
              <label htmlFor="equipmentType">Equipment Type</label>
              <br></br>
              <select
                value={equipmentType}
                onChange={(e) => setEquipmentType(e.target.value)}
                id="equipmentType"
                name="equipmentType">
                <option value={""} disabled={true}>
                  Select Equipment Type
                </option>
                <option value={"AUTO"}>Auto Carrier</option>
                <option value={"B-TR"}>B-Train</option>
                <option value={"DUMP"}>Dump Trailer</option>
                <option value={"F"}>Flatbed</option>
                <option value={"SD"}>Step Deck</option>
                <option value={"HOPP"}>Hopper Bottom</option>
                <option value={"LBO_RGN"}>Lowboy or RGN</option>
                <option value={"R"}>Reefer</option>
                <option value={"RGN"}>Removable Gooseneck</option>
                <option value={"TANK"}>Tanker</option>
                <option value={"V"}>Van</option>
                <option value={"OTHER"}>Other</option>
              </select>
            </div>

            <div>
              <label htmlFor="hazmatLicense">Hazmat License Required</label>
              <br></br>
              <input
                id="hazmatLicense"
                type="checkbox"
                checked={hazmatLicenseRequired}
                onChange={(e) => setHazmatLicenseRequired(e.target.checked)}
              />
            </div>
            <div>
              <label htmlFor="doublesEndorsement">Doubles Endorsement Required</label>
              <br></br>
              <input
                id="doublesEndorsement"
                type="checkbox"
                checked={doublesEndorsementRequired}
                onChange={(e) => setDoublesEndorsementRequired(e.target.checked)}
              />
            </div>
            <div>
              <label htmlFor="tankerEndorsement">Tanker Endorsement Required</label>
              <br></br>
              <input
                id="tankerEndorsement"
                type="checkbox"
                checked={tankerEndorsementRequired}
                onChange={(e) => setTankerEndorsementRequired(e.target.checked)}
              />
            </div>
            <div>
              <label htmlFor="twicCard">TWIC Required</label>
              <br></br>
              <input
                id="twicCard"
                type="checkbox"
                checked={twicRequired}
                onChange={(e) => setTwicRequired(e.target.checked)}
              />
            </div>
            <div>
              <label htmlFor="tsaCertified">TSA Certification Required</label>
              <br></br>
              <input
                id="tsaCertified"
                type="checkbox"
                checked={tsaCertifiedRequired}
                onChange={(e) => setTsaCertifiedRequired(e.target.checked)}
              />
            </div>

            <div>
              <label htmlFor="specialInstructions">Special Instructions</label>
              <br></br>
              <textarea
                id="specialInstructions"
                placeholder="(optional)"
                value={specialInstructions}
                onChange={(e) => setSpecialInstructions(e.target.value)}
              />
            </div>
          </div>
        </div>
        <br></br>

        <div>
          <p
            style={{
              textAlign: "center",
              color: `${isErrorMessage ? "#DC3545" : "green"}`,
              fontWeight: "600",
              paddingBlock: "1rem",
            }}>
            {message}
          </p>
        </div>
        <br></br>
        <button type="submit">Create</button>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </form>
    </div>
  );
};

export default CreateLoad;
