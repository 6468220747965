import React, { useState, useContext } from "react";
import "./SignUp.css";
import { supabase } from "../../supabase/supabase";
import lanesLogo from "../../images/Logo_Color.svg";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/userContext";

function SignUp() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setcPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [userType, setUserType] = useState("");
  const [username, setUsername] = useState("");
  const [formIsValid, setFormIsValid] = useState(false);

  const navigate = useNavigate();
  const { setUserId } = useContext(UserContext);

  const isEmailValid = (email) => {
    // Simple email validation regex
    const emailRegex = /\S+@\S+\.\S+/;
    return emailRegex.test(email);
  };

  const updateFormValid = () => {
    if (
      firstName === "" ||
      lastName === "" ||
      userType === "" ||
      email === "" ||
      password === "" ||
      cpassword === "" ||
      !isEmailValid(email) ||
      password !== cpassword
    ) {
      setFormIsValid(false);
      alert("Please fill out all fields correctly.");
    } else {
      setFormIsValid(true);
    }
  };

  const signUpWithEmail = async () => {
    updateFormValid();
    if (formIsValid) {
      setLoading(true);
      const {
        data: { session },
        error,
      } = await supabase.auth.signUp({
        email: email,
        password: password,
      });

      if (error) {
        setLoading(false);
        alert("There was an issue. Please try again later.");
      } else {
        const user_id = session.user.identities[0].user_id;
        // Set the user context and user type
        await setUserId(user_id, userType);

        updateProfile(user_id);

        setEmail("");
        setPassword("");
        setFirstName("");
        setLastName("");
        setcPassword("");
        setPhone("");
        setUserType("");
        setUsername("");

        setLoading(false);

        // Navigate to the Dashboard
        navigate("/dashboard");
      }
    }
  };

  const updateProfile = async (user_id) => {
    try {
      const { data, error } = await supabase
        .from("profiles")
        .update({
          full_name: firstName + " " + lastName,
          user_type: userType,
          username: username,
          phone_number: phone,
          payment_active: false,
        })
        .eq("id", user_id);

      if (error) {
        throw new Error(`Error updating load: ${error.message}`);
      }

      return Promise.resolve();
    } catch (error) {
      throw new Error(`Error updating load: ${error.message}`);
    }
  };

  return (
    <div className="container-signup">
      <div className="sign_up_logo_center">
        <img src={lanesLogo} alt="Lanes Logo" />
      </div>
      <div className="signup-form">
        <input
          type="text"
          placeholder="First Name"
          className="form-input-signup"
          value={firstName}
          onChange={(e) => {
            setFirstName(e.target.value);
          }}
        />
        <input
          type="text"
          placeholder="Last Name"
          className="form-input-signup"
          value={lastName}
          onChange={(e) => {
            setLastName(e.target.value);
          }}
        />
        <input
          type="text"
          placeholder="Username"
          className="form-input-signup"
          value={username}
          onChange={(e) => {
            setUsername(e.target.value);
          }}
        />
        <input
          type="text"
          placeholder="Email"
          className="form-input-signup"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <input
          type="text"
          placeholder="Phone"
          className="form-input-signup"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />

        <div className="user_type_signup">
          <label>Account Type: </label>
          <label>
            <input
              type="radio"
              value="driver"
              checked={userType === "driver"}
              onChange={(e) => {
                setUserType(e.target.value);
              }}
            />
            Driver
          </label>
          <label>
            <input
              type="radio"
              value="shipper"
              checked={userType === "shipper"}
              onChange={(e) => {
                setUserType(e.target.value);
              }}
            />
            Shipper
          </label>
        </div>

        <input
          type="password"
          placeholder="Password"
          className="form-input-signup"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
        <input
          type="password"
          placeholder="Confirm Password"
          className="form-input-signup"
          value={cpassword}
          onChange={(e) => {
            setcPassword(e.target.value);
          }}
        />
        <button className="btn btn-signup" onClick={signUpWithEmail}>
          {loading ? "Loading..." : "Submit"}
        </button>
      </div>
    </div>
  );
}

export default SignUp;
