import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoInformationCircle } from 'react-icons/io5';
import { getAllGroupsForUser, getLatestMessageForGroups } from '../../supabase/groups';
import { UserContext } from '../../context/userContext';
import { markAsRead } from '../../supabase/groupMembership';
import './Inbox.css';

const Inbox = () => {
  const navigate = useNavigate();
  const [groups, setGroups] = useState([]);
  const { userId } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = useCallback(async () => {
    try {
      const userGroups = await getAllGroupsForUser(userId);
      const groupMessages = await getLatestMessageForGroups(userGroups);

      // Sort groups so that unread messages are at the top
      groupMessages.sort((a, b) => (b.unread ? 1 : 0) - (a.unread ? 1 : 0));

      setGroups(groupMessages);
    } catch (error) {
      console.error('Error fetching groups:', error);
    } finally {
      if (isLoading) {
        setIsLoading(false); // Set loading state to false only on initial load completion
      }
    }
  }, [userId, isLoading]);

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 12000);
    return () => clearInterval(intervalId);
  }, [fetchData]);

  const truncateMessage = (message, maxLength = 23) => {
    if (typeof message === 'string') {
      if (message.length > maxLength) {
        return message.substring(0, maxLength) + '...';
      }
      return message;
    }
    return '';
  };

  const handleMarkingRead = async (group_id) => {
    await markAsRead(group_id, userId);
  };

  const renderItem = (item) => (
    <div
      key={item.group_id}
      className="item-container"
      onClick={() => {
        navigate('/chat', {
          state: {
            group_id: item.group_id,
            group_name: item.group_name,
          },
        });
        handleMarkingRead(item.group_id);
      }}
    >
      {item.unread && <div className="notification-dot"></div>}
      <div className="preview-message-container">
        <span className="item">{item.group_name}</span>
        <span className="latest-message">
          {truncateMessage(item.latest_message?.content)}
        </span>
      </div>
    </div>
  );

  return (
    <div className="inbox-container">
      {isLoading ? (
        <div className="activity-indicator">Loading...</div>
      ) : (
        <div className="container-mobile">
          <div className="group-name-container">
            <span className="group-name">Messages</span>
            <button className="info-button">
              <IoInformationCircle size={30} />
            </button>
          </div>
          <div className="list-mobile">
            {groups.map((group) => renderItem(group))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Inbox;
