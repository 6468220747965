import React, { useState, useContext, useEffect } from "react";
import "./CompletedLoads.css";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/userContext";
import { getCurrentUserProfile } from "../../supabase/profile";
import { getCurrentShipperLoads, getCurrentDriverLoads } from "../../supabase/loads";
import { getPendingLoadsDriver, getPendingLoadsShipper } from "../../supabase/userloads";
import { IoRefresh } from "react-icons/io5";
import { FaEnvelope, FaFile, FaBox, FaTruck } from "react-icons/fa";

const CompletedLoads = () => {
  const navigate = useNavigate();
  const { userId } = useContext(UserContext);
  const [loadData, setLoadData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [isShipper, setIsShipper] = useState(false);
  const [isDriver, setIsDriver] = useState(false);
  //   const [activeTab, setActiveTab] = useState("new"); // 'all' or 'complete'
  const [pendingLoadsData, setPendingLoadsDriver] = useState([]);

  // Fetch user profile data on component mount
  useEffect(() => {
    if (userId) {
      // Get information about the user so we can determine if they are a shipper or driver
      getCurrentUserProfile(userId)
        .then((userData) => {
          setUserData(() => userData[0]);

          // Display their list of loads depending on whether they are a driver or shipper
          if (userData[0].user_type === "shipper") {
            setIsShipper(() => true);
          } else if (userData[0].user_type === "driver") {
            setIsDriver(() => true);
          } else {
            setIsShipper(() => false);
          }
        })
        .catch((error) => {
          console.error("Error fetching user profile:", error);
        });

      if (isShipper) {
        getCurrentShipperLoads(userId)
          .then((loadData) => {
            setLoadData(() => loadData);
          })
          .catch((error) => {
            console.error("Error fetching load data:", error);
          });
      } else {
        getCurrentDriverLoads(userId)
          .then((loadData) => {
            setLoadData(() => loadData);
          })
          .catch((error) => {
            console.error("Error fetching load data:", error);
          });

        getPendingLoadsDriver(userId)
          .then((loadData) => {
            setPendingLoadsDriver(() => loadData);
          })
          .catch((error) => {
            console.error("Error fetching load data:", error);
          });
      }
    }
  }, [userId, isShipper]);

  const handleRefresh = () => {
    if (isShipper) {
      getCurrentShipperLoads(userId)
        .then((loadData) => {
          setLoadData(loadData);
        })
        .catch((error) => {
          console.error("Error fetching load data:", error);
        });
    } else {
      getCurrentDriverLoads(userId)
        .then((loadData) => {
          setLoadData(loadData);
        })
        .catch((error) => {
          console.error("Error fetching load data:", error);
        });

      getPendingLoadsDriver(userId)
        .then((loadData) => {
          setPendingLoadsDriver(loadData);
        })
        .catch((error) => {
          console.error("Error fetching load data:", error);
        });
    }
  };

  // Filter loads based on status
  const allLoads = loadData
    ? [
        ...loadData,
        ...pendingLoadsData.filter(
          (pendingLoad) => !loadData.some((load) => load.load_id === pendingLoad.load_id)
        ),
      ]
    : [];
  const completeLoads = allLoads.filter((load) => load.load_status === "complete");

  // Function to handle row click and navigate to load details page
  const handleRowClick = (loadId) => {
    navigate(`/load-details/${loadId}`);
  };

  return (
    <>
      {loadData ? (
        <>
          <div className="tabs_completed_loads">
            <div className="active">Completed</div>
          </div>

          <div className="user-profile">
            <div className="table-container-dashboard">
              <table className="table-dashboard">
                <thead>
                  <tr>
                    <th>Origin</th>
                    <th>Rate</th>
                    <th>Destination</th>
                    <th>
                      Status{" "}
                      <button className="refresh-button" onClick={handleRefresh}>
                        <IoRefresh size={20} />
                      </button>{" "}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {completeLoads.map((load, index) => (
                    <tr
                      key={index}
                      className="pill-row"
                      onClick={() => handleRowClick(load.load_id)}>
                      <td>
                        {load.origin_address_city}, {load.origin_address_state}
                      </td>
                      <td>${load.rate_per_mile.toFixed(2)}</td>
                      <td>
                        {load.destination_address_city}, {load.destination_address_state}
                      </td>
                      <td>{load.load_status}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <div className="no-account-info">
          <p>No load information found at this time.</p>
        </div>
      )}
    </>
  );
};

export default CompletedLoads;
