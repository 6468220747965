// Authory Ryan Pinkney
// Handle sending system messages from Lanes

import { supabase } from './supabase';

// The function sends the message and updates the corresponding to group to have unread set to true
const sendMessage = async (content, groupId, senderId) => {
  
      // Insert the new message into the database
      const { error } = await supabase.from('messages').insert([{        
        content: content,
        group_id: groupId,
        sender_id: senderId, // If you sending a message as Lanes, use 5eadf59a-46dc-42d6-b71a-047b1105e62d in your function call
        message_type: "TEXT",
        created_at: new Date(),}
      ]);

      if (error) {
        console.error('Error sending message:', error);
      }

        // Update the corresponding group with the groupId and set 'unread' to true
        const { error: groupError } = await supabase
        .from('group_membership')
        .update({ unread: true })
        .eq('group_id', groupId)
        .neq('user_id', senderId)

      if (groupError) {
        console.error('Error updating group unread status:', groupError);
}
  
};

export {sendMessage}