//Tyler Steele
// to delete a load you just need to provide a valid loadId to the deleteLoad() call.
// Probably need to update to validate that loadId is valid and exists.

import { supabase } from './supabase';

const deleteLoad  = async (loadId) => { 
    
    const { error1 } = await supabase
        .from('userloads')
        .delete()
        .eq('load_id', loadId)

    const { error2 } = await supabase
        .from('loads')
        .delete()
        .eq('load_id', loadId)

    if (error1) {
        console.error('Error deleting load from userloads table:', error1);
        return;
    }

    if (error2) {
        console.error('Error deleting load from loads table:', error2);
    }
}

// Export all the functions
export {deleteLoad}