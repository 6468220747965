// David Schraedel
// page for leaving a review for the other party assigned to a load

import React, { useContext, useEffect, useState } from "react";
import { createReview, getReview } from "../../supabase/review";
import { useParams } from "react-router-dom";
import { UserContext } from "../../context/userContext";
import { getCurrentUserProfile } from "../../supabase/profile";
import "./Review.css";
import Rating from "react-rating";
import { useNavigate } from "react-router-dom";

const Review = () => {
  const { userId } = useContext(UserContext);
  const [recipientData, setRecipientData] = useState(null);
  const [numberOfStars, setNumberOfStars] = useState(0);
  const [reviewText, setReviewText] = useState("");
  const { recipientId, loadId, isDriver } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [inputError, setInputError] = useState("");
  const navigate = useNavigate();

  const requiredMarker = <span className="review-required-marker">*</span>;

  useEffect(() => {
    getRecipientData();
  }, []);

  const getRecipientData = async () => {
    await getCurrentUserProfile(recipientId).then((recipientProfile) => {
      setRecipientData(() => recipientProfile[0]);
      setIsLoading(() => false);
    });
  };

  function handleRatingChange(value) {
    setNumberOfStars(() => value);
  }

  const handleSubmitReview = () => {
    if (numberOfStars === 0) {
      setInputError("Please choose a rating out of 5");
    }
    {
      setInputError(() => "");
      createReview(userId, recipientId, loadId, numberOfStars, reviewText);
    }

    // navigate to load details
    navigate(`/load-details/${loadId}`);
  };

  function handleNavigateToLoadDetails() {
    navigate(`/load-details/${loadId}`);
  }

  return (
    <>
      {!isLoading && (
        <>
          <div className="button-container">
            {/* Back Button */}
            <div className="back-button-container">
              <button className="back-button" onClick={handleNavigateToLoadDetails}>
                &lt;
              </button>
            </div>
          </div>
          <div className="leave-review-container">
            <div className="photo-review-recipient">
              <img src={recipientData.avatar_url} alt="Reviewee's profile avatar" />
              <h1>{recipientData.full_name}</h1>
            </div>
            <div className="review-rating-container">
              <Rating
                initialRating={numberOfStars}
                emptySymbol={<span className="icon-star-empty">&#9734;</span>}
                fullSymbol={<span className="icon-star-full">&#9733;</span>}
                className="custom-rating-review"
                onChange={handleRatingChange}
              />
              {requiredMarker}
            </div>
            <div>
              <textarea
                type="text"
                placeholder="Comments"
                className="form-input review-comment"
                value={reviewText}
                onChange={(e) => setReviewText(e.target.value)}
              />
              <button className="btn btn-submit-review" onClick={() => handleSubmitReview()}>
                {isLoading ? "Loading..." : "Submit Review"}
              </button>
              {inputError && <div className="review-input-error-message">{inputError}</div>}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Review;
