// David Schraedel & Stetson Kent
// show all reviews that the current user received

import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getReviewsForAUser } from "../../supabase/review";
import { UserContext } from "../../context/userContext";
import "./UserReviews.css";
import Rating from "react-rating";

const UserReviews = () => {
  const { userId } = useContext(UserContext);
  const navigate = useNavigate();
  const [reviews, setReviews] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const handleBackToDashboard = () => {
    navigate(-1); // back to wherever the user came from
  };

  useEffect(() => {
    getReviewList();
  }, []);

  const getReviewList = async () => {
    const userReviews = await getReviewsForAUser(userId);
    setReviews(userReviews);
    setIsLoading(false);
  };

  return (
    <>
      <div className="reviews-header">
        <h2>Reviews</h2>
      </div>

      <div className="back-button-container">
        <button className="back-button" onClick={handleBackToDashboard}>
          &lt;
        </button>
      </div>

      <div className="reviews-container">
        <div className="list-received-reviews-container">
          {!isLoading &&
            reviews.map((receivedReview, index) => (
              <div key={index} className="received-review-container">
                <div className="review-submitter">
                  {`${receivedReview.submitterName}`}
                </div>
                <Rating
                  initialRating={receivedReview.number_of_stars}
                  emptySymbol={<span className="icon-star-empty">&#9734;</span>}
                  fullSymbol={<span className="icon-star-full">&#9733;</span>}
                  readonly
                  className="custom-rating-list-reviews"
                />
                {receivedReview.review_text ? (
                  <div className="received-review-comment">{receivedReview.review_text}</div>
                ) : (
                  <div className="received-review-no-comment">No Comment Given</div>
                )}
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default UserReviews;
