//Stetson Kent

import React, { useState } from "react";
import "./Pricing.css";
import { useNavigate } from "react-router-dom";

function Pricing() {
  const [activeTab, setActiveTab] = useState("drivers");
  const navigate = useNavigate();

  const driverFeatures = (
    <>
      <p className="section-header">Standout Features</p>
      <ul className="features-list">
        <li>Full access to the web and mobile apps</li>
        <li>All-in-one load board and management solution</li>
        <li>Built in messaging</li>
        <li>Store and send required documents</li>
        <li>Proof of delivery made easy</li>
        <li>Real time notifications</li>
        <li>Familiar search/filter features</li>
      </ul>
      <p className="section-header">Route Optimization Tool</p>
      <ul className="features-list">
        <li>Book multiple loads at once</li>
        <li>Set custom trip length</li>
        <li>Maximize profit</li>
      </ul>
    </>
  );

  const shipperFeatures = (
    <>
      <p className="section-header">Standout Features</p>
      <ul className="features-list">
        <li>Full access to the web and mobile apps</li>
        <li>All-in-one load board and management solution</li>
        <li>Built in messaging</li>
        <li>Store and send rate confirmation, proof of delivery, and other required documents</li>
        <li>Real time notifications</li>
        <li>Shipping process tracking</li>
      </ul>
    </>
  );

  return (
    <div className="pricing-container">
      <div className="banner-container">
        <div className="banner">
          <div
            className={activeTab === "drivers" ? "active" : ""}
            onClick={() => setActiveTab("drivers")}>
            Drivers
          </div>
          <div
            className={activeTab === "shippers" ? "active" : ""}
            onClick={() => setActiveTab("shippers")}>
            Shippers
          </div>
        </div>
      </div>

      <div className="pricing-plans">
        <div className="pricing-plan">
          <h2>Standard Plan</h2>
          <p className="price">
            {activeTab === "drivers" ? "$99" : "$199"}
            <span>/month</span>
          </p>
          {activeTab === "drivers" ? driverFeatures : shipperFeatures}
        </div>
        <div className="pricing-plan">
          <div className="savings-banner">
            Save {activeTab === "drivers" ? "$88" : "$88"} per year!
          </div>
          <h2>Yearly Plan</h2>
          <p className="price">
            {activeTab === "drivers" ? "$1100" : "$2300"}
            <span>/year</span>
          </p>
          {activeTab === "drivers" ? driverFeatures : shipperFeatures}
        </div>
      </div>

      <div className="signup_link_pricing">
        <button className="btn-signup-pricing " onClick={() => navigate("/signup")}>
          Sign Up
        </button>
      </div>
    </div>
  );
}

export default Pricing;
