import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "./context/userContext"; // Import your user context

const ProtectedRoute = ({ children }) => {
  const { userId } = useContext(UserContext);

  if (!userId) {
    // If not authenticated, redirect to the login page
    return <Navigate to="/login" replace />;
  }

  // If authenticated, render the protected component
  return children;
};

export default ProtectedRoute;
