// Author: Ryan Pinkney
// This file handles all the group chat function calls do the database

import { supabase } from './supabase';

// Function to get all the users for a given group chat
const getAllGroupsForUser = async (userId) => {

    const { data: groupData, error: groupError } = await supabase
        .from('group_membership')
        .select(`
            group_id,
            groups:group_id (group_name),
            unread
        `)
        .eq('user_id', userId);

    if (groupError) {
        console.error('Error fetching group data:', groupError);
        return [];
    } else {
        return groupData.map(group => ({ 
            group_id: group.group_id, 
            group_name: group.groups.group_name,
            unread: group.unread
        }));
    }
};
  

const getLatestMessageForGroups = async (groups) => {
    const groupMessages = [];

    for (const group of groups) {
        const { data: messageData, error: messageError } = await supabase
            .from('messages')
            .select('*')
            .eq('group_id', group.group_id)
            .order('created_at', { ascending: false })
            .limit(1);

        if (messageError) {
            console.error(`Error fetching messages for group ${group.group_id}:`, messageError);
            continue;
        }

        groupMessages.push({ 
            group_id: group.group_id, 
            group_name: group.group_name,
            unread: group.unread,
            latest_message: messageData && messageData.length > 0 ? messageData[0] : null 
        });
    }

    return groupMessages;
};

const getUsersInGroup = async (group_id) => {
    const {data: groupMemberData, error: usersError} = await supabase
    .from('group_membership')
    .select('*')
    .eq('group_id', group_id)

    if (usersError) {
        console.log("Error Getting Group Members", usersError)
    }else{
        let aGroupMembers = [];
        groupMemberData.map((item) => {
            aGroupMembers.push(item.user_id)
        })

        const { data: groupMemberProfileData, error: groupMemberProfileError } = await supabase
        .from('user_profiles')
        .select('*')
        .in('user_id', aGroupMembers);

        if (groupMemberProfileError){
            return console.log("Error Retrieving Profiles of Group Members", groupMemberProfileError)
        } else{
            return groupMemberProfileData;
        }
    }
}

// Creates a new groups record
// Pass a groupName and ownerId to be used in the function
const createGroup  = async (groupName, ownerId) => { 

    const { data, error } = await supabase
    .from('groups')
    .insert([
        {   
        group_name: groupName,
        owner_id: ownerId,
        created_at: new Date(),
        },
    ])
    .select()

    if (error) {
    console.error('Error adding group:', error);
    return;
    } else {
    return data;
    }
}

// Function that will find the LANES CHAT for the given user id
const getLanesChatId = async (user_id) => {
    if(!user_id) {
        console.error('No shipper is listed on this load')
    }


        const { data: data, error: error } = await supabase
            .from('groups')
            .select('*')
            .eq('owner_id', user_id)
            .eq('group_name', 'Lanes 🚚')

        if (error) {
            console.error('Error fetching Lanes Chat id:', error);
        }

    return data[0].group_id;
};

export {getAllGroupsForUser, getLatestMessageForGroups, getUsersInGroup, createGroup, getLanesChatId}