// Kyle Klintworth
import { supabase } from "./supabase";
import { decode } from "base64-arraybuffer";

const uploadFileUserBucket = async (file, user_id) => {
  try {
    if (!file) {
      console.log("Error", "Please provide a file before updating.");
      return null;
    }

    // Read the file as a base64 string
    const reader = new FileReader();
    reader.readAsDataURL(file);

    const base64 = await new Promise((resolve, reject) => {
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
    });

    // Upload the file to Supabase
    const { data, error } = await supabase.storage
      .from("user_files")
      .upload(`${user_id}/${file.name}`, decode(base64), {
        contentType: file.type,
        upsert: true,
        cacheControl: "3600",
      });

    if (error) {
      throw error;
    }

    const publicUrl = await getDocumentPublicUrl(user_id, file.name);

    if (publicUrl) {
      return publicUrl;
    } else {
      console.log("Error", "Failed to generate public URL");
      return null;
    }
  } catch (error) {
    console.error("Error uploading file:", error.message);
    console.log("Error", "Failed to upload file. Please try again.");
    return null;
  }
};

async function getDocumentPublicUrl(user_id, fileName) {
  try {
    const { data, error } = await supabase.storage
      .from("user_files")
      .getPublicUrl(`${user_id}/${fileName}`);

    if (error) {
      console.error("Error generating public URL:", error.message);
      return null;
    }

    // Return the public URL
    return data.publicUrl;
  } catch (err) {
    console.error("Error:", err.message);
    return null;
  }
}

export { uploadFileUserBucket, getDocumentPublicUrl };
