import React, { useState, useContext, useEffect } from "react";
import "./Account.css";
import { useNavigate, useLocation } from "react-router-dom";
import { UserContext } from "../../context/userContext";
import { getCurrentUserProfile } from "../../supabase/profile";
import account_icon from "../../images/account_icon.png";
import Rating from "react-rating";
import { getCurrentDriverLoads, getCurrentShipperLoads } from "../../supabase/loads";

const Account = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userId } = useContext(UserContext);
  const [userData, setUserData] = useState(null);
  const [userCompletedLoads, setUserCompletedLoads] = useState(null);

  // Fetch user profile data on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userId) {
          const profileData = await getCurrentUserProfile(userId);

          if (profileData && profileData.length > 0) {
            const userProfile = profileData[0];
            setUserData(userProfile);
            if (userProfile.user_type === "driver") {
              const loads = await getCurrentDriverLoads(userId);
              setUserCompletedLoads(loads.length);
            } else {
              const loads = await getCurrentShipperLoads(userId);
              setUserCompletedLoads(loads.length);
            }
          } else {
            console.error("User profile data not found");
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [userId]);

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <>
      {userData ? (
        <div className="user-profile">
          <img
            src={userData.avatar_url ? userData.avatar_url : account_icon}
            alt={userData.avatar_url ? "User Image" : "Account Icon"}
          />

          <div
            className=""
            style={{
              marginTop: 0,
              // display: "grid",
              // gridTemplateColumns: "repeat(2, 1fr)",
              gap: "5px",
              width: "100%",
            }}>
            <div>
              <p
                style={{
                  fontSize: 28,
                  fontWeight: "bold",
                  marginTop: 5,
                  marginBottom: 5,
                }}>
                {userData.full_name}
              </p>

              <Rating
                initialRating={userData.rating}
                emptySymbol={<span className="icon-star-empty">&#9734;</span>}
                fullSymbol={<span className="icon-star-full">&#9733;</span>}
                readonly
                className="custom-rating-account" // Apply the custom class here
              />
              <div>
                <button
                  className={`my_loads_completed_button ${
                    location.pathname === "/dashboard" ? "selected" : ""
                  }`}
                  onClick={() => handleNavigate("/dashboard")}>
                  Current Loads
                </button>
              </div>
              <div>
                <button
                  className={`my_loads_completed_button ${
                    location.pathname === "/loads-complete" ? "selected" : ""
                  }`}
                  onClick={() => handleNavigate("/loads-complete")}>
                  Completed Loads: {userCompletedLoads}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="no-account-info">
          <p>No account information found at this time.</p>
        </div>
      )}
    </>
  );
};

export default Account;
