// Author Stetson Kent
// Upload the rate con to supabase
import { supabase } from './supabase';

// Function to upload the rate confirmation PDF to Supabase
const uploadRateConfirmation = async (file, load_id, isSigned) => {
  try {
    if (!file) {
      console.log('Error: Please provide a file before updating.');
      return null;
    }

    console.log('Uploaded PDF:', file.name, 'Load ID:', load_id);

    // Append "_signed" to the file name if it is the signed version
    const fileName = isSigned ? file.name.replace('.pdf', '_signed.pdf') : file.name;

    // Use FormData to prepare the file for upload
    const formData = new FormData();
    formData.append('file', file);

    // Pass to Supabase
    const { data, error } = await supabase.storage
      .from('rate_confirmations') // Replace with your storage bucket name
      .upload(load_id + '/' + fileName, file, { 
        contentType: 'application/pdf',
        upsert: true, 
        cacheControl: "3600",
      });

    if (error) {
      throw error;
    }

    // Successfully uploaded
    console.log('Success: PDF updated successfully!');

    const publicUrl = await getRateConfirmationPublicUrl(load_id, fileName);

    if (publicUrl) {
      console.log('Public URL:', publicUrl);
      return publicUrl;
    } else {
      console.log('Error: Failed to generate public URL');
      return null;
    }
  } catch (error) {
    console.error('Error uploading rate confirmation:', error.message);
    console.log('Error: Failed to upload rate confirmation. Please try again.');
  }
};

// Function to get the public URL of the uploaded file
const getRateConfirmationPublicUrl = async (load_id, fileName) => {
  try {
    // Generate a signed URL for the file
    const { data, error } = await supabase.storage
      .from('rate_confirmations')
      .getPublicUrl(load_id + '/' + fileName, 1680000); // The 60 is the expiry time in seconds

    if (error) {
      console.error('Error generating signed URL:', error.message);
      return null;
    }

    // Return the public URL
    return data.publicUrl;
  } catch (error) {
    console.error('Error:', error.message);
    return null;
  }
};

// Function to delete the rate confirmation
const deleteRateConfirmation = async (load_id) => {
  try {
    // Delete the rate confirmation folder after uploading
    await supabase.storage
      .from('rate_confirmations')
      .remove([load_id]);

    console.log('Rate confirmation folder deleted:', load_id);
  } catch (error) {
    console.error('Error deleting rate confirmation:', error.message);
  }
};

export { uploadRateConfirmation, getRateConfirmationPublicUrl, deleteRateConfirmation };
