// Author: Ryan Pinkney
// This page will display if the user is a shipper

import React, { useState, useContext, useEffect } from "react";
import "./Dashboard.css";
import { useNavigate, useLocation } from "react-router-dom";
import { UserContext } from "../../context/userContext";

import { getCurrentUserProfile } from "../../supabase/profile";

const Dashboard = ({ children }) => {
  // const [selectedOption, setSelectedOption] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const { userId } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);

  const [userData, setUserData] = useState();

  // Fetch user profile data on component mount
  useEffect(() => {
    setIsLoading(true);
    getCurrentUserProfile(userId)
      .then((profileData) => {
        setUserData(() => profileData[0]);
      })
      .catch((error) => {
        console.error("Error fetching user profile:", error);
      });
    setIsLoading(false);
  }, [userId]);

  return (
    <>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div className="dashboard">
          <div className="sidebar">
            <div
              className={`sidebar-option ${location.pathname === "/dashboard" ? "active" : ""}`}
              onClick={() => navigate("/dashboard")}>
              Home
            </div>
            {userData && userData.user_type === "shipper" && (
              <div
                className={`sidebar-option ${location.pathname === "/new_load" ? "active" : ""}`}
                onClick={() => navigate("/new_load")}>
                New Load
              </div>
            )}
            {userData && userData.user_type === "driver" && (
              <div
                className={`sidebar-option ${location.pathname === "/search" ? "active" : ""}`}
                onClick={() => navigate("/search")}>
                Search
              </div>
            )}
            <div
              className={`sidebar-option ${location.pathname === "/inbox" ? "active" : ""}`}
              onClick={() => navigate("/inbox")}>
              Inbox
            </div>
          </div>

          <div className="main-content">{children}</div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
