//David Schraedel 

import { Link } from "react-router-dom";
import "./ProfileDropdown.css";
import genericAccountIcon from "../../images/account_icon.png";
import { useContext, useEffect, useState, useRef } from "react";
import { getCurrentUserProfile } from "../../supabase/profile";
import { UserContext } from "../../context/userContext";
import { IoCloseSharp } from "react-icons/io5";
import { useLocation } from "react-router-dom";

const ProfileDropdown = ({ isDropdownOpen, handleDropDownMenuState }) => {
  const location = useLocation();
  const { sessionData, userId } = useContext(UserContext);
  const [userAvatarImg, setUserAvatarImg] = useState("");
  const [userData, setUserData] = useState("");
  const dropdownRef = useRef(null);

  useEffect(() => {
    getUserData();
  }, [userId]);

  const getUserData = async () => {
    if (userId) {
      await getCurrentUserProfile(userId)
        .then((profileData) => {
          if (profileData) {
            profileData[0].avatar_url
              ? setUserAvatarImg(profileData[0].avatar_url)
              : setUserAvatarImg(genericAccountIcon);
            setUserData(profileData[0]);
          }
        })
        .catch((error) => {
          console.error("Error fetching user profile avatar:", error);
        });
    }
  };

  const handleSetupClick = () => {
    if (userData.user_type === "shipper") {
      window.location.href = "https://buy.stripe.com/8wM7tDcGH2k534YaEE";
    } else {
      window.location.href = "https://buy.stripe.com/bIY8xH0XZ9Mx8pi6op";
    }
  };

  const handleManageClick = () => {
    window.location.href = "https://billing.stripe.com/p/login/00g8wK72kgw1gAE9AA";
  };

  // Added by Stetson Kent, closes dropdown menu when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        handleDropDownMenuState(); // Close the dropdown menu
      }
    };

    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen, handleDropDownMenuState]);

  return (
    <>
      {userData && (
        <div ref={dropdownRef} className={`dropdown_menu_container ${isDropdownOpen ? "active" : "inactive"}`}>
          <div className="dropdown_menu_header">
            <div className="profile_identity">
              <Link to={"/user-account"}>
                <img className="dropdown_menu_user_avatar" src={userAvatarImg} alt="Menu" />
              </Link>
            </div>
            <div className="dropdown_menu_close_icon" onClick={handleDropDownMenuState}>
              <IoCloseSharp size={20} />
            </div>
          </div>

          <div className="profile_info">
            <div className="profile_name">{userData.full_name}</div>
            <Link to={"/user-account"}>
              <div className="profile_username" onClick={handleDropDownMenuState}>
                {userData.username}
              </div>
            </Link>
          </div>

          <section className="dropdown_menu_main">
            <div>
              <div className="dropdown_menu_divider"></div>

              <Link to={"/dashboard"}>
                <div className={`dropdown_menu_link ${location.pathname === "/dashboard" && "active"}`}>
                  Dashboard
                </div>
              </Link>

              <Link to={"/user-account"}>
                <div className={`dropdown_menu_link ${location.pathname === "/user-account" && "active"}`}>
                  Profile
                </div>
              </Link>

              {!userData.payment_active ? (
                <div className={`dropdown_menu_link `} onClick={handleSetupClick}>
                  Setup Billing
                </div>
              ) : (
                <div className={`dropdown_menu_link `} onClick={handleManageClick}>
                  Manage Billing
                </div>
              )}

              <Link to={"/user-reviews"}>
                <div className={`dropdown_menu_link ${location.pathname === "/user-reviews" && "active"}`}>
                  Reviews
                </div>
              </Link>

              <Link to={"/customer-support"}>
                <div className={`dropdown_menu_link ${location.pathname === "/customer-support" && "active"}`}>
                  Technical Support
                </div>
              </Link>

              <div className="dropdown_menu_divider"></div>

              <div className={"dropdown_menu_link "} onClick={() => sessionData.signOut()}>
                Sign Out
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
};

export default ProfileDropdown;
