// Author: Ryan Pinkney
// Define all the functions that interact with all our data tables
import { supabase } from './supabase';

const createRequest  = async (driverId, loadId) => { 

    const { data, error } = await supabase
    .from('requests')
    .insert([
        {   
        driver_id: driverId,
        load_id: loadId,
        status: 'pending',
        },
    ])
    .select()

    if (error) {
    console.error('Error adding request:', error);
    return;
    } else {
    return data;
    }
};

// Function used in the delete load function
const deleteAllRequests = async (load_id, ) => {
    const { error } = await supabase
      .from('requests')
      .delete()
      .eq('load_id', load_id);
  
    if (error) {
      console.error('Error deleting requests:', error);
      return;
    } else {
      return;
    }
  };
  



export { createRequest, deleteAllRequests }