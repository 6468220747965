// Author: Ryan Pinkney & Stetson Kent
// Allows the user to submit a review
import { supabase } from "./supabase";

// Inserts a review
const createReview = async (submitterId, recipientId, loadId, numberOfStars, reviewText) => {
  // Create the review record
  const { data: reviewdata, error: reviewerror } = await supabase
    .from("reviews")
    .insert([
      {
        number_of_stars: numberOfStars,
        review_text: reviewText,
      },
    ])
    .select("*");

  if (reviewerror) {
    console.error("Error adding review:", reviewerror);
    return;
  } else {
    console.log("Record created");
  }

  // Create the linking record for the submitter to track who submitted the review
  const { error: submittererror } = await supabase
    .from("userreviews")
    .insert([
      {
        review_id: reviewdata[0].id,
        user_id: submitterId,
        load_id: loadId,
        relationship_type: "submitter",
      },
    ])
    .select();

  if (submittererror) {
    console.error("Error adding userreview:", submittererror);
    return;
  } else {
    console.log("Record created for the submitter in userreviews");
  }

  // Create the linking record for the recipient to track who received the review
  const { error: recipienterror } = await supabase
    .from("userreviews")
    .insert([
      {
        review_id: reviewdata[0].id,
        user_id: recipientId,
        load_id: loadId,
        relationship_type: "recipient",
      },
    ])
    .select();

  if (recipienterror) {
    console.error("Error adding userreview:", recipienterror);
    return;
  } else {
    console.log("Record created for the recipient in userreviews");
  }

  // Now that we created the review, we need to calculate the average score the recipient and update their profile
  console.log("Calculating the recipients review");

  // Query all reviews related to the recipientId
  const { data: recipientReviews, error: recipientReviewsError } = await supabase
    .from("userreviews")
    .select("review_id")
    .eq("user_id", recipientId)
    .eq("relationship_type", "recipient");

  // Check for errors in the review query
  if (recipientReviewsError) {
    console.error("Error querying reviews for recipient:", recipientReviewsError.message);
    return;
  }

  // Extract review_id values from recipientReviews
  const reviewIds = recipientReviews.map((review) => review.review_id);

  // Query all reviews with review_id in reviewIds
  const { data: allReviews, error: allReviewsError } = await supabase
    .from("reviews")
    .select("*")
    .in("id", reviewIds);

  // Check for errors in the second review query
  if (allReviewsError) {
    console.error("Error querying all reviews:", allReviewsError.message);
    return;
  }

  // Calculate the average
  const totalStars = allReviews.reduce((sum, review) => sum + review.number_of_stars, 0);
  const averageStars = totalStars / recipientReviews.length;

  // Update the profiles table with the calculated average rating
  const { data: profileData, error: profileError } = await supabase
    .from("profiles")
    .update({ rating: averageStars })
    .eq("id", recipientId)
    .single();

  // Check for errors in the profile update
  if (profileError) {
    console.error("Error updating profile:", profileError.message);
    return;
  }

  console.log("Review created successfully. Average rating updated:", averageStars);
};

async function getReview(submitterId, loadId) {
  // request userreview record
  const { data: userReviewData, error: userReviewError } = await supabase
    .from("userreviews")
    .select("*")
    .eq("load_id", loadId)
    .eq("user_id", submitterId)
    .eq("relationship_type", "submitter");

  let reviewId;

  if (userReviewError) {
    console.error("Error requesting review-user association:", userReviewError);
    return;
  } else {
    if (userReviewData[0]) {
      reviewId = userReviewData[0].review_id;
    } else {
      return;
    }
  }

  // request review record via review_id from userreview object
  const { data: reviewData, error: reviewError } = await supabase
    .from("reviews")
    .select("*")
    .eq("id", reviewId);

  if (reviewError) {
    console.error("Error requesting review:", reviewError);
    return null;
  } else {
    return reviewData[0];
  }
}
 
async function getUserReviewsByLoadAndUser(loadId, userId) {
  try {
    const { data, error } = await supabase
      .from("userreviews")
      .select("*")
      .eq("load_id", loadId)
      .eq("user_id", userId)
      .eq("relationship_type", "submitter");

    if (error) {
      throw new Error(`Error fetching user reviews: ${error.message}`);
    }

    return data;
  } catch (error) {
    console.error("Error in getUserReviewsByLoadAndUser:", error.message);
    return null;
  }
}

//Author: Stetson Kent 
// Gets all the reviews for the user and joins the submitter name to the review
async function getReviewsForAUser(userId) {
  // Query all reviews related to the recipientId
  const { data: recipientReviews, error: recipientReviewsError } = await supabase
    .from("userreviews")
    .select("review_id")
    .eq("user_id", userId)
    .eq("relationship_type", "recipient");

  // Check for errors in the review query
  if (recipientReviewsError) {
    console.error("Error querying reviews for recipient:", recipientReviewsError.message);
    return [];
  }

  // Extract review_id values from recipientReviews
  const reviewIds = recipientReviews.map((review) => review.review_id);

  if (reviewIds.length === 0) {
    return [];
  }

  // Query all userreviews with review_id in reviewIds and relationship_type of 'submitter'
  const { data: submitterReviews, error: submitterReviewsError } = await supabase
    .from("userreviews")
    .select("review_id, user_id")
    .in("review_id", reviewIds)
    .eq("relationship_type", "submitter");

  // Check for errors in the second query
  if (submitterReviewsError) {
    console.error("Error querying submitter reviews:", submitterReviewsError.message);
    return [];
  }

  // Extract the submitter user IDs
  const submitterUserIds = submitterReviews.map((review) => review.user_id);

  // Query all profiles with user_id in submitterUserIds
  const { data: submitterProfiles, error: submitterProfilesError } = await supabase
    .from("profiles")
    .select("id, full_name")
    .in("id", submitterUserIds);

  // Check for errors in the profiles query
  if (submitterProfilesError) {
    console.error("Error querying submitter profiles:", submitterProfilesError.message);
    return [];
  }

  // Create a map of submitter IDs to names
  const submitterMap = {};
  submitterProfiles.forEach((profile) => {
    submitterMap[profile.id] = profile.full_name;
  });

  // Query all reviews with review_id in reviewIds
  const { data: allReviews, error: allReviewsError } = await supabase
    .from("reviews")
    .select("*")
    .in("id", reviewIds);

  // Check for errors in the third review query
  if (allReviewsError) {
    console.error("Error querying all reviews:", allReviewsError.message);
    return [];
  }

  // Map the reviews to include submitter name
  const reviewsWithSubmitter = allReviews.map((review) => {
    const submitter = submitterReviews.find((ur) => ur.review_id === review.id);
    const submitterName = submitter ? submitterMap[submitter.user_id] : null;
    return {
      ...review,
      submitterName,
    };
  });

  return reviewsWithSubmitter;
}


export { createReview, getReview, getUserReviewsByLoadAndUser, getReviewsForAUser };
